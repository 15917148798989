
import { GridColDef } from '@mui/x-data-grid';
import { ListPageLayout } from 'src/shared/layouts/page/list-page-layout';
import { UIDataGrid } from 'src/shared/components/ui/ui-data-grid';
import { FC, useState } from 'react';
import { useMount } from 'src/shared/hooks/use-mount';
import { Api } from 'src/api/api';
import { ApiQuery } from 'src/api/api-query';
import UIMessage from 'src/shared/components/ui/ui-message';
import { Chip, Skeleton } from '@mui/material';
import { GetLookupRowsQuery, GetLookupRowsQueryResult } from 'src/api/queries/lookup/get-lookup-rows';
import { Permission } from 'src/models/permission';
import { useNavigate } from 'react-router';
import { useAuth } from 'src/shared/hooks/use-auth';
import { paths } from 'src/routes/paths';

interface LookupListPageProps {
    permissions: Permission[];
	title: string;
	pageSize: number;
	apiQuery: ApiQuery;
	addButtonPath?: string | undefined; 
    addButtonPermissions?: Permission[] |  undefined;
    loadingRows?: any[] | undefined;
}

const LookupListPage: FC<LookupListPageProps> = (props) => {
    const {title, pageSize, apiQuery, addButtonPath, permissions, addButtonPermissions} = props;
    const auth = useAuth();
    const navigate = useNavigate();
    const addButtonAccessible = auth.authState.principal?.hasAnyPermission(addButtonPermissions || []);

    const loadingRows: any[] = ((rows: number) => {
        return [...Array(rows)].map((row, index) => {
            return {
                id: Number.MAX_SAFE_INTEGER - index,
                name: null,
                isActive: null,
                operationName: null
            }
        });
    })(pageSize);
    
    const columns: GridColDef[] = [
        {
            field: 'id',
            headerName: 'ID',
            flex: 0.1,
            align: 'left',
            headerAlign: 'left',
            filterable: false,
            renderCell: (params) => params.value >= Number.MAX_SAFE_INTEGER - pageSize ? <Skeleton animation="wave" variant="text" /> : params.value,
        },
        {
            field: 'name',
            headerName: 'Name',
            flex: 0.20,
            align: 'left',
            headerAlign: 'left',
            filterable: false,
            renderCell: (params) => params.value == null ? <Skeleton animation="wave" variant="text" /> : params.value,
        },
        {
            field: 'isActive',
            headerName: 'Status',
            flex: 0.20,
            align: 'left',
            headerAlign: 'left',
            filterable: false,
            renderCell: (params) => params.value == null? <Skeleton animation="wave" variant="text" /> : params.value ?  <Chip size="small" label="Active" color="success" variant="outlined"/> :  <Chip size="small" label="Inactive" color="warning" variant="outlined" />,
        },
    ];
	const [rows, setRows] = useState<any[]>(loadingRows);
	const [loading, setLoading] = useState<boolean>(true);
	const [errors, setErrors] = useState<string[]>([]);

	useMount(() => {

		const loadData = async () => {
			try {
				const result = await Api.executeQuery<GetLookupRowsQuery, GetLookupRowsQueryResult>(apiQuery, {});
				setRows(result.rows);
				setLoading(false);
			} catch (err) {
				setErrors(err.response.data.errors || [err.response.statusText])
				setLoading(false);
			}
		}

        if(!auth.authState.principal?.hasAnyPermission(permissions)) {
            navigate(paths.error403);
            return;
        }

		loadData();
	});

	return (
		<ListPageLayout
			title={title}
            addButtonAccessible={addButtonAccessible}
			addButtonPath={addButtonPath}>
			<UIMessage
				type="error"
				messages={errors}
				onClose={() => setErrors([])} />

			<UIDataGrid
				loading={loading}
				hideFooterPagination={loading}
				rows={rows}
				columns={columns}
                initialState={{
                    pagination: {
                        paginationModel: {
                            pageSize: pageSize
                        },
                    },
                }}
			/>

		</ListPageLayout>
	);
};

export default LookupListPage;
