import axios, { AxiosRequestConfig } from "axios";
import { RefreshTokenCommand, RefreshTokenCommandResult } from "./commands/principal";
import { ApiCommand } from "./api-command";
import { ApiQuery } from "./api-query";
import { Buffer } from 'buffer';
import { ArtifactFileType } from "src/models/artifact-file";

export const Api = (() => {
    const accessTokenKey = "accessToken";
    const refreshTokenKey = "refreshToken";
    //const baseUrl = "http://localhost:7173/api";
    const baseUrl = 'https://devapi.mfrd.net/api';


    const imageApi = axios.create({
        baseURL: baseUrl,
        responseType: 'arraybuffer'
    });
    imageApi.interceptors.request.use(
        (config) => {
            const token = localStorage.getItem(accessTokenKey);
            if (token) {
                config.headers.Authorization = `Bearer ${token}`;
            }
            return config;
        },
        (error) => Promise.reject(error)
    );

    const jsonApi = axios.create({
        baseURL: baseUrl,
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
        },
    });

    // Add a request interceptor to add the JWT token to the authorization header
    jsonApi.interceptors.request.use(
        (config) => {
            const token = localStorage.getItem(accessTokenKey);
            if (token) {
                config.headers.Authorization = `Bearer ${token}`;
            }
            return config;
        },
        (error) => Promise.reject(error)
    );

    // Add a response interceptor to refresh the JWT token if it's expired
    jsonApi.interceptors.response.use(
        (response) => response,
        (error) => {
            const originalRequest = error.config as AxiosRequestConfig<any>;
            // console.log("error");
            // console.log(originalRequest);
            const refreshToken = localStorage.getItem(refreshTokenKey);
    
            if( error.response.status === 401 && !refreshToken) {
                window.location.href = '/login';
            }else if ( error.response.status === 401 && refreshToken) {
              
                let refreshTokenCommand: RefreshTokenCommand = {
                    refreshToken
                };

                executeCommand<RefreshTokenCommand,RefreshTokenCommandResult>(ApiCommand.RefreshToken, refreshTokenCommand)
                    .then((response) => {
                        
                        localStorage.setItem(accessTokenKey, response.accessToken);
                        localStorage.setItem(refreshTokenKey, refreshToken);

                        // Re-run the original request that was intercepted
                        if(originalRequest.headers) {
                            originalRequest.headers.Authorization = `Bearer ${response.accessToken}`;
                            return  jsonApi(originalRequest)
                                .then(response => response.data)
                                .catch((err) => {
                                    throw err;
                                });
                        }
                    
                    })
                    .catch((err) => {
                        // If there is an error refreshing the token, log out the user
                        // console.log(err);
                        localStorage.removeItem(accessTokenKey);
                        localStorage.removeItem(refreshToken);
                    });
            }else {
                // Return the original error if we can't handle it
                return Promise.reject(error);
            }
     
        }
    );


    const getArtifactImageData = (artifactId: number | null| undefined, fileId: number) => {
        return imageApi
            .get("/images/artifacts/" + (artifactId ?? 0) + "/" + fileId)
            .then((r) => {
                return Buffer.from(r.data).toString('base64');
            })
            .catch((err) => {
                throw err;
            });
    }

    const uploadArtifactImageData = (artifactId: number, artifactFileType: ArtifactFileType, file: Blob) => {

        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }

        const formData = new FormData();
        formData.append('file',file)
        
        return jsonApi
        .post("/images/artifacts/" + artifactId + "/upload/" + artifactFileType, formData, config)
        .then(({ data }) => data)
        .catch((err) => {
            throw err;
        });
      }

    const executeCommand = <TCommand, TCommandResult>(
        code: ApiCommand,
        command: TCommand
        ) => {
        return jsonApi
            .post("/command", {
                code: ApiCommand[code],
                command
            })
            .then(({ data }) => data as TCommandResult)
            .catch((err) => {
                throw err;
            });
    };


    const executeQuery = <TQuery, TQueryResult>(
        code: ApiQuery,
        query: TQuery
    ) => {
        return jsonApi
            .post("/query", {
                code: ApiQuery[code],
                query
            })
            .then(({ data }) => data as TQueryResult)
            .catch((err) => {
                throw err;
            });
    };

    return {
        executeCommand,
        executeQuery,
        getArtifactImageData,
        uploadArtifactImageData
    };
})();
