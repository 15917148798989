import { Unstable_Grid2 as Grid, TextField } from "@mui/material";
import { FC } from "react";
import { UIFieldProps } from "./ui-field";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

interface UIDateFieldProps extends UIFieldProps{
    name: string;
    label: string;
    required: boolean
}

const UIDateField: FC<UIDateFieldProps> = (props) => {
    const { name, label, formik, required} = props;
    return !formik ? (<></>): (
        <Grid
            xs={12}
            md={12}
        >
            <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
                key={name}
                label={label}
                value={formik.values[name] ?? null}
                //onChange={formik.handleChange}
                onChange={(value) => formik.setFieldValue(name, value, true)}
                renderInput={(props) => <TextField fullWidth={true} required={required} value={formik.values[name] ?? null} {...props} />}
                />
            </LocalizationProvider>
        </Grid>
    );
};

export default UIDateField;
