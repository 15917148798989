import { type FC } from 'react';
import Menu01Icon from '@untitled-ui/icons-react/build/esm/Menu01';
import { Box, IconButton, Stack, SvgIcon } from '@mui/material';
import { alpha } from '@mui/material/styles';
import { AccountButton } from './account-button';
import { useNav } from '../../../hooks/use-nav';

const TOP_NAV_HEIGHT: number = 64;

export const TopNav: FC = () => {
  const nav = useNav();
  return (
    <Box
      component="header"
      sx={{
        backdropFilter: 'blur(6px)',
        backgroundColor: (theme) => nav.isOpen ? alpha(theme.palette.background.default, 0.8) : alpha('#1C2536',0.98),
        borderBottom: 'solid 1px #eeeeee',
        position: 'sticky',
        left: nav.isOpen ?  `${nav.sideNavWidth}px` : 0,
        top: 0,
        width: nav.isOpen ?  `calc(100% - ${nav.sideNavWidth}px)` : '100%',
        zIndex: (theme) => theme.zIndex.appBar
      }}
    >
      <Stack
        alignItems="center"
        direction="row"
        justifyContent="space-between"
        spacing={2}
        sx={{
          minHeight: TOP_NAV_HEIGHT,
          px: 2
        }}
      >
        <Stack
          alignItems="center"
          direction="row"
          spacing={2}
        >
          {!nav.isOpen && (
            <img src="/assets/logo.png"
                style={{ width: 160 }} />
          )}
             <IconButton onClick={nav.handleToggle}>
              <SvgIcon>
                <Menu01Icon />
              </SvgIcon>
            </IconButton>
          {/* <SearchButton /> */}
        </Stack>
        <Stack
          alignItems="center"
          direction="row"
          spacing={2}
        >
          {/*<NotificationsButton />*/}
          <AccountButton />
        </Stack>
      </Stack>
    </Box>
  );
};

