
import { GridColDef, GridColumnVisibilityModel } from '@mui/x-data-grid';
import { ListPageLayout } from 'src/shared/layouts/page/list-page-layout';
import { UIDataGrid } from 'src/shared/components/ui/ui-data-grid';
import { FC, useState } from 'react';
import { useMount } from 'src/shared/hooks/use-mount';
import { Api } from 'src/api/api';
import { ApiQuery } from 'src/api/api-query';
import UIMessage from 'src/shared/components/ui/ui-message';
import { GetArtifactDescriptionsQuery, GetArtifactDescriptionsQueryResult } from 'src/api/queries/artifact-description/get-artifact-descriptions';
import { paths } from 'src/routes/paths';
import { nameof } from 'src/shared/utils/nameof';
import { ArtifactDescriptionRow } from 'src/models/artifact-description';
import { textColumn, yesNoColumn, activeInactiveColumn, idColumn, editActionColumn } from 'src/shared/components/grid-columns';
import { useAuth } from 'src/shared/hooks/use-auth';
import { useNavigate } from 'react-router';
import { Permission } from 'src/models/permission';

const ArtifactDescriptionListPage: FC = () => {
    const auth = useAuth();
    const navigate = useNavigate();
	const title = "Artifact Descriptions";
	const pageSize =  25;
	const addButtonPath = paths.admin.artifactDescriptions.create;
    const permissions = [Permission.DataManagementRead];
    const addButtonAccessible = auth.authState.principal?.hasPermission(Permission.DataManagementCreate) || false;
    const editButtonAccessible = auth.authState.principal?.hasPermission(Permission.DataManagementEdit) || false;

    const loadingRows: any[] = ((rows: number) => {
        return [...Array(rows)].map((row, index) => {
            return {
                id: Number.MAX_SAFE_INTEGER - index,
                name: null,
                isActive: null,
            }
        });
    })(pageSize);
    

    const columns: GridColDef[] = [
        idColumn(pageSize, nameof<ArtifactDescriptionRow>("id")),
        textColumn(nameof<ArtifactDescriptionRow>("name"),"Name",0.30),
        textColumn(nameof<ArtifactDescriptionRow>("defaultArtifactTypeName"),"Default Artifact Type"),
        textColumn(nameof<ArtifactDescriptionRow>("defaultArtifactStatusName"),"Default Artifact Status"),
        textColumn(nameof<ArtifactDescriptionRow>("defaultDispositionName"),"Default Disposition"),
        yesNoColumn(nameof<ArtifactDescriptionRow>("defaultDivisonEligible"),"Default Divison Eligible", 0.15),
        textColumn(nameof<ArtifactDescriptionRow>("defaultDivisionPoints"),"Default Division Points", 0.15),
        textColumn(nameof<ArtifactDescriptionRow>("defaultProtocolName"),"Default Protocol"),
        textColumn(nameof<ArtifactDescriptionRow>("defaultConservatorName"),"Default Conservator"),
        textColumn(nameof<ArtifactDescriptionRow>("defaultConcretionRemovalMethodName"),"Default Concretion Removal"),
        textColumn(nameof<ArtifactDescriptionRow>("defaultChlorideRemovalName"),"Default Chloride Removal"),
        textColumn(nameof<ArtifactDescriptionRow>("defaultChlorideReleaseTestName"),"Default Chloride Release Test"),
        textColumn(nameof<ArtifactDescriptionRow>("defaultChemicalStabilizationName"),"Default Chemical Stabilization"),
        activeInactiveColumn(nameof<ArtifactDescriptionRow>("isActive"),"Status"),
        editActionColumn(editButtonAccessible, id => paths.admin.artifactDescriptions.update.replace(":id", id))
    ];
	const [rows, setRows] = useState<any[]>(loadingRows);
	const [loading, setLoading] = useState<boolean>(true);
	const [errors, setErrors] = useState<string[]>([]);
    const [columnVisibilityModel, setColumnVisibilityModel] = useState<GridColumnVisibilityModel>({
        // Default hidden columns
        [nameof<ArtifactDescriptionRow>("defaultConservatorName")]: false,
        [nameof<ArtifactDescriptionRow>("defaultConcretionRemovalMethodName")]: false,
        [nameof<ArtifactDescriptionRow>("defaultChlorideRemovalName")]: false,
        [nameof<ArtifactDescriptionRow>("defaultChlorideReleaseTestName")]: false,
        [nameof<ArtifactDescriptionRow>("defaultChemicalStabilizationName")]: false,
      });

	useMount(() => {

		const loadData = async () => {
			try {
				const result = await Api.executeQuery<GetArtifactDescriptionsQuery, GetArtifactDescriptionsQueryResult>(
					ApiQuery.GetArtifactDescriptions, {});
				setRows(result.rows);
				setLoading(false);
			} catch (err) {
				setErrors(err.response.data.errors || [err.response.statusText])
				setLoading(false);
			}
		}

        if(!auth.authState.principal?.hasAnyPermission(permissions)) {
            navigate(paths.error403);
            return;
        }

		loadData();
	});

	return (
		<ListPageLayout
			title={title}
			addButtonPath={addButtonPath}
            addButtonAccessible={addButtonAccessible}>
			<UIMessage
				type="error"
				messages={errors}
				onClose={() => setErrors([])} />

			<UIDataGrid
				loading={loading}
				hideFooterPagination={loading}
				rows={rows}
				columns={columns}
                columnVisibilityModel={columnVisibilityModel}
                onColumnVisibilityModelChange={(newModel) =>
                 setColumnVisibilityModel(newModel)
                }
                disableColumnSelector={false}
                slotProps={{
                    toolbar: {
                        showQuickFilter: true,
                        csvOptions: { disableToolbarButton: false },
                        printOptions: { disableToolbarButton: true },
                    },
                }}
			/>
  
		</ListPageLayout>
	);
};

export default ArtifactDescriptionListPage;

