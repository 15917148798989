import { paths } from 'src/routes/paths';
import { Api } from 'src/api/api';
import { ApiCommand, CreateCommandResult, UpdateCommandResult } from "src/api/api-command";
import { CreateSafeCommand, UpdateSafeCommand } from 'src/api/commands/safe';
import UISubPage from 'src/shared/components/ui/ui-sub-page';
import { useNavigate, useParams } from 'react-router-dom';
import UIForm, { UIFormMode } from 'src/shared/components/ui/ui-form';
import { FC, useState } from 'react';
import { GetSafeQuery, GetSafeQueryResult } from 'src/api/queries/safe/get-safe';
import { ApiQuery } from 'src/api/api-query';
import toast from 'react-hot-toast';
import { UIProgress } from 'src/shared/components/ui/ui-progress';
import UISelectField, { UISelectFieldItem, UISelectFieldType } from 'src/shared/components/ui/ui-select-field';
import UITextField from 'src/shared/components/ui/ui-text-field';
import { nameof } from 'src/shared/utils/nameof';
import UIStatusRadioField, { UIStatusRadioFieldValue } from 'src/shared/components/ui/ui-status-radio-field';
import { GetBuildingsQuery, GetBuildingsQueryResult } from 'src/api/queries/building/get-buildings';
import { useMount } from 'src/shared/hooks/use-mount';
import { Permission } from 'src/models/permission';
import { useAuth } from 'src/shared/hooks/use-auth';

interface SafeFormFields {
  id?: number;
  name: string;
  description?: string;
  buildingId:number;
  isActive?: UIStatusRadioFieldValue;
}

interface SafeFormDatasources {
  buildings: UISelectFieldItem<number>[];
}

interface SafeFormData {
  mode: UIFormMode;
  datasources: SafeFormDatasources;
  fields: SafeFormFields
}

interface SafeFormProps {
  entityName: string;
  cancelHref: string;
  data: SafeFormData;
  onSave: (mode: UIFormMode, data: SafeFormFields) => Promise<number>;
  onCompleted: (id: number) => Promise<void>;
}

const SafeForm: FC<SafeFormProps> = (props) => {

  const { entityName, cancelHref, data: { mode, datasources, fields }, onSave, onCompleted } = props;

  const onBeforeSave = async (mode: UIFormMode, form: SafeFormFields) => {
    return form;
  };

  return (
    <UIForm
      mode={mode}
      entity={entityName}
      cancelHref={cancelHref}
      onBeforeSave={onBeforeSave}
      onSave={onSave}
      onCompleted={onCompleted}
      fields={fields}>

    <UISelectField
        type={UISelectFieldType.Single}
        name={nameof(fields, "buildingId")}
        label="Building"
        items={datasources.buildings}
        required={true} />

      <UITextField
        name={nameof(fields, "name")}
        label="Name"
        required={true} />

      <UITextField
        name={nameof(fields, "description")}
        label="Description"
        multilineRows={5}
        required={false} />

      {mode === UIFormMode.Create  ? (<></>) : (

        <UIStatusRadioField
          name={nameof(fields, "isActive")}
          label="Status"
          required={true}
        />

      )}


    </UIForm>
  );
};

const SafeManagePage = () => {
  const entityName = "Safe";
  const entityListPath = paths.admin.safes.list;
  const [pageTitle, setPageTitle] = useState<string>("");
  const [formData, setFormData] = useState<SafeFormData>();
  const { id } = useParams();
  const navigate = useNavigate();
  const auth = useAuth();
  
  const onSave = async (mode: UIFormMode, fields: SafeFormFields): Promise<number> => {

    if (mode === UIFormMode.Update) {
      const command: UpdateSafeCommand = {
        ...fields,
        id: fields.id!,
        isActive: fields.isActive === UIStatusRadioFieldValue.Active ? true : false
      };

      await Api.executeCommand<UpdateSafeCommand, UpdateCommandResult>(
        ApiCommand.UpdateSafe,
        command);

      return command.id;

    } else {
      const { id, isActive, ...createFields } = fields;
      const command: CreateSafeCommand = {
        ...createFields,
      };

      const result = await Api.executeCommand<CreateSafeCommand, CreateCommandResult>(
        ApiCommand.CreateSafe,
        command);

      return result.id;
    }

  };

  const onCompleted = async (id: number) => {
    navigate(paths.admin.safes.list)
  }

  useMount(() => {
    let aborted = false;
    const idNumber = +(id || 0); //Router Param
    const mode: UIFormMode = idNumber ? UIFormMode.Update : UIFormMode.Create;
    const permission = (mode === UIFormMode.Create) ? Permission.DataManagementCreate : Permission.DataManagementEdit;    
    if (!auth.authState.principal?.hasPermission(permission)) {
      navigate(paths.error403);
      return;
    }

    async function getBuildingSelectFieldItems(): Promise<UISelectFieldItem<number>[]> {
      const result = await Api.executeQuery<GetBuildingsQuery, GetBuildingsQueryResult>(ApiQuery.GetBuildings, {});
      const rows = result?.rows.map<UISelectFieldItem<number>>(r => ({ label: r.name, value: r.id })) || [];
      //rows.unshift({ value: 0, label: "" })
      return rows;
    }

    async function onPageLoad() {
      try {
        let record: SafeFormFields;
        setPageTitle(`${entityName} > ${UIFormMode[mode]}`);
        if (mode === UIFormMode.Update) {

          const result = await Api.executeQuery<GetSafeQuery, GetSafeQueryResult>(
            ApiQuery.GetSafe, { id: idNumber }
          );

          const { isActive, ...fields } = result.record
          record = {
            ...fields,
            isActive: isActive ? UIStatusRadioFieldValue.Active : UIStatusRadioFieldValue.Inactive
          };

        } else {
          record = {
            name: "",
            buildingId: 0
          }
        }

        //Will use this or descriptions in the future
        const Buildings = await getBuildingSelectFieldItems();


        if (aborted)
          return;

        setFormData({
          mode,
          fields: { ...record },
          datasources: {
            buildings: Buildings
          }
        });


      } catch (err) {
        console.log(err);
        toast.error(`${entityName} could not be loaded.`);
        navigate(paths.admin.buildings.list)
      }

    }

    onPageLoad();

    return () => {
      aborted = true;
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  });



  return (
    <UISubPage
      title={pageTitle}
      backTitle={entityName}
      backHref={entityListPath}>
      {!formData ? (
        <UIProgress />
      ) : (
        <SafeForm
          entityName={entityName}
          cancelHref={entityListPath}
          onSave={onSave}
          onCompleted={onCompleted}
          data={formData} />
      )}
    </UISubPage>
  );
};

export default SafeManagePage;
