import { FC, ReactNode } from 'react';
import ArrowLeftIcon from '@untitled-ui/icons-react/build/esm/ArrowLeft';
import { Box, Container, Link, Stack, SvgIcon, Typography } from '@mui/material';
import { RouterLink } from 'src/shared/components/router-link';
import { Seo } from 'src/shared/components/seo';

interface UISubPageProps {
    title: string;
    backTitle: string;
    backHref: string;
    children: ReactNode;
}
const UISubPage: FC<UISubPageProps> = (props) => {
  return (
    <>
      <Seo title={props.title} />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 3
        }}
      >
        <Container maxWidth="xl">
          <Stack spacing={4}>
            <Stack spacing={4}>
              <div>
                <Link
                  color="text.primary"
                  component={RouterLink}
                  href={props.backHref}
                  sx={{
                    alignItems: 'center',
                    display: 'inline-flex'
                  }}
                  underline="hover"
                >
                  <SvgIcon sx={{ mr: 1 }}>
                    <ArrowLeftIcon />
                  </SvgIcon>
                  <Typography variant="subtitle2">
                    {props.backTitle}
                  </Typography>
                </Link>
              </div>
            </Stack>
                  {props.children}
          </Stack>
        </Container>
      </Box>
    </>
  );
};
export default UISubPage;
