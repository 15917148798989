import * as Yup from 'yup';
import { useFormik } from 'formik';
import { Box, Button, FormHelperText, Link, Stack, TextField, Typography } from '@mui/material';
import { Seo } from 'src/shared/components/seo';
import type { AuthContextType } from 'src/shared/contexts/auth-context';
import { useAuth } from 'src/shared/hooks/use-auth';
import { useIsMounted } from 'src/shared/hooks/use-is-mounted';
import { useSearchParams } from 'src/shared/hooks/use-search-params';
import { paths } from 'src/routes/paths';
import { useState } from 'react';
import { RouterLink } from 'src/shared/components/router-link';
import { useMount } from 'src/shared/hooks/use-mount';

interface Values {
  email: string;
  password: string;
  mfaCode?: string;
  submit: null;
}

const initialValues: Values = {
  email: '',
  password: '',
  submit: null
};

const validationSchema = Yup.object({
  email: Yup
    .string()
    .max(255)
    .required('Email address is required.'),
  password: Yup
    .string()
    .max(255)
    .required('Password is required.')
});

const LoginPage = () => {

  const isMounted = useIsMounted();
  const searchParams = useSearchParams();
  const [mfaRequired, setMfaRequired] = useState<boolean>(false);
  const returnTo = searchParams.get('returnTo');
  const { signIn, refreshAuthState } = useAuth<AuthContextType>();
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values, helpers): Promise<void> => {
      try {
        helpers.setSubmitting(true);
        const result = await signIn(values.email, values.password, values.mfaCode);
        if (result.mfaRequired) {
          helpers.setSubmitting(false);
          setMfaRequired(true);
        } else {
          helpers.setStatus({ success: true });
          window.location.href = returnTo || paths.dashboard;      // returnTo could be an absolute path
        }

      } catch (err) {
        console.error(err);

        if (isMounted()) {
          helpers.setStatus({ success: false });
          helpers.setErrors({ submit: err.response.data.errors });
          helpers.setSubmitting(false);
        }
      }
    }
  });

  useMount(() => {
    refreshAuthState(); //Necessary for pages that must not be authenticted.
  });

  return (
    <>
      <Seo title="Login" />
      <div>

        <Stack
          sx={{ mb: 4 }}
          spacing={1}
        >
          <Typography
            variant="h5"
          >
            Log in
          </Typography>

        </Stack>
        <form
          noValidate
          onSubmit={formik.handleSubmit}
        >
          {formik.errors.submit && (
            <FormHelperText
              error
              sx={{ mt: 3, mb: 3 }}
            >
              {formik.errors.submit as string}
            </FormHelperText>
          )}


          <Stack spacing={3}>
            <TextField
              autoFocus
              error={!!(formik.touched.email && formik.errors.email)}
              fullWidth
              helperText={formik.touched.email && formik.errors.email}
              label="Email Address"
              name="email"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              type="email"
              value={formik.values.email}
            />
            <TextField
              error={!!(formik.touched.password && formik.errors.password)}
              fullWidth
              helperText={formik.touched.password && formik.errors.password}
              label="Password"
              name="password"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              type="password"
              value={formik.values.password}
            />
            {mfaRequired && (
              <>
                <FormHelperText>
                  A one time security code has been sent via SMS.
                </FormHelperText>
                <TextField
                  error={!!(formik.touched.mfaCode && formik.errors.mfaCode)}
                  fullWidth
                  helperText={formik.touched.mfaCode && formik.errors.mfaCode}
                  label="One Time Security Code"
                  name="mfaCode"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  type="text"
                  value={formik.values.mfaCode}
                />
              </>
            )}

          </Stack>
          <Button
            fullWidth
            sx={{ mt: 3 }}
            size="large"
            type="submit"
            variant="contained"
            disabled={formik.isSubmitting}
          >
            Continue
          </Button>

          {mfaRequired ? (
          <Box sx={{ mt: 3 }}>
            <Link
               component={RouterLink}
              href={paths.dashboard}
              underline="hover"
              variant="subtitle2"
            >
              Start Over
            </Link>
          </Box>
          ) : (
            <Box sx={{ mt: 3 }}>
            <Link
              component={RouterLink}
              href={paths.password.forgot}
              underline="hover"
              variant="subtitle2"
            >
              Forgot password?
            </Link>
          </Box>
          )}

        </form>
      </div>
    </>
  );
};


export default LoginPage;
