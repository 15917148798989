import { paths } from 'src/routes/paths';
import { Api } from 'src/api/api';
import { ApiQuery } from "src/api/api-query";
import { ApiCommand } from "src/api/api-command";
import { UpdateUserCommand, UpdateUserCommandResult } from 'src/api/commands/user';
import UISubPage from 'src/shared/components/ui/ui-sub-page';
import { useNavigate, useParams } from 'react-router-dom';
import { UIFormMode } from 'src/shared/components/ui/ui-form';
import { UserForm, UserFormData, UserFormFields } from 'src/pages/admin/user/components/user-form';
import { useState } from 'react';
import { GetUserQuery, GetUserQueryResult } from 'src/api/queries/user/get-user';
import { MultiFactorAuthenticationMethod } from 'src/models/mfa';
import { useAuth } from 'src/shared/hooks/use-auth';
import { useMount } from 'src/shared/hooks/use-mount';
import { Permission } from 'src/models/permission';
import { UIProgress } from 'src/shared/components/ui/ui-progress';
import { Role } from 'src/models/role';
import { getLookupSelectFieldItems, LookupSelectFieldItemLabel } from 'src/shared/utils/get-lookup-select-items';


const UserUpdatePage = () => {
  const [formData, setFormData] = useState<UserFormData>();
  const { userId } = useParams();
  
  const navigate = useNavigate();
  const auth = useAuth();

  useMount(()=>{
    let aborted = false;
    if (!auth.authState.principal?.hasPermission(Permission.Administration)) {
      navigate(paths.error403);
      return;
    }

    
    async function loadPageData() {
      const idNumber = +(userId || 0); //Router Param
      const operations = await getLookupSelectFieldItems(ApiQuery.GetOperations, LookupSelectFieldItemLabel.None);
      const user = (await Api.executeQuery<GetUserQuery, GetUserQueryResult>(
        ApiQuery.GetUser, 
        {id: idNumber}))?.record;

      if (aborted)
        return;

      const fields : UserFormFields = {
       ...user
      }

      setFormData({
        mode: UIFormMode.Update,
        datasources: {
          operations
        },
        fields
      })

    }

    loadPageData();

    return () => {
      aborted = true;
    };
  });

  const onSave = async (mode: UIFormMode, fields: UserFormFields) => {
    const { mfaMethod, ...updateFields} = fields;

    const command: UpdateUserCommand = {
      ...updateFields,
      mfaMethod: mfaMethod === MultiFactorAuthenticationMethod.None ? null : mfaMethod 

    };

    await Api.executeCommand<UpdateUserCommand, UpdateUserCommandResult>(
      ApiCommand.UpdateUser,
      command);

      return fields.id ?? "";
  };

  const onCompleted = async (id: number) => {
    navigate(paths.admin.users.list)
  }


  
  return (
    <UISubPage
      title="Admin > Users > Update"
      backTitle="Users"
      backHref={paths.admin.users.list}>
    {!formData ? (

       <UIProgress />
 
      ) : (
      <UserForm 
        onSave={onSave} 
        onCompleted={onCompleted} 
        data={formData} />
    )}
    </UISubPage>
  );
};

export default UserUpdatePage;
