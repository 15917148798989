import type { FC } from 'react';
import { useCallback } from 'react';
import PropTypes from 'prop-types';
import toast from 'react-hot-toast';
import Settings04Icon from '@untitled-ui/icons-react/build/esm/Settings04';
import {
	Box,
	Button,
	Divider,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	Popover,
	SvgIcon,
	Typography
} from '@mui/material';
import { RouterLink } from 'src/shared/components/router-link';
import { useAuth } from 'src/shared/hooks/use-auth';
import { useRouter } from 'src/shared/hooks/use-router';
import { paths } from 'src/routes/paths';

interface AccountPopoverProps {
	anchorEl: null | Element;
	onClose?: () => void;
	open?: boolean;
}

export const AccountPopover: FC<AccountPopoverProps> = (props) => {
	const { anchorEl, onClose, open, ...other } = props;
	const router = useRouter();
	const auth = useAuth();
	const user = auth.authState.principal;

	const handleLogout = useCallback(
		async (): Promise<void> => {
			try {
				onClose?.();

				await auth.signOut();

				router.push(paths.login);
			} catch (err) {
				console.error(err);
				toast.error('Something went wrong!');
			}
		},
		[auth, router, onClose]
	);

	return (
		<Popover
			anchorEl={anchorEl}
			anchorOrigin={{
				horizontal: 'center',
				vertical: 'bottom'
			}}
			disableScrollLock
			onClose={onClose}
			open={!!open}
			PaperProps={{ sx: { width: 200 } }}
			{...other}
		>
			<Box sx={{ p: 2 }}>
				<Typography variant="body1">
					{user?.firstName}
				</Typography>
				<Typography
					color="text.secondary"
					variant="body2"
				>
					{user?.emailAddress}
				</Typography>
			</Box>
			<Divider />
			<Box sx={{ p: 1 }}>
				<ListItemButton
					component={RouterLink}
					href={paths.dashboard}
					onClick={onClose}
					sx={{
						borderRadius: 1,
						px: 1,
						py: 0.5
					}}
				>
					<ListItemIcon>
						<SvgIcon fontSize="small">
							<Settings04Icon />
						</SvgIcon>
					</ListItemIcon>
					<ListItemText
						primary={(
							<Typography variant="body1">
								Settings
							</Typography>
						)}
					/>
				</ListItemButton>

			</Box>
			<Divider sx={{ my: '0 !important' }} />
			<Box
				sx={{
					display: 'flex',
					p: 1,
					justifyContent: 'center'
				}}
			>
				<Button
					color="inherit"
					onClick={handleLogout}
					size="small"
				>
					Logout
				</Button>
			</Box>
		</Popover>
	);
};

AccountPopover.propTypes = {
	anchorEl: PropTypes.any,
	onClose: PropTypes.func,
	open: PropTypes.bool
};
