import { Permission } from "./permission";
import { Role } from "./role";

export interface IPrincipal {
  firstName: string;
  lastName: string;
  emailAddress: string;
  role: number;
  defaultOperationId?: number | null;
  extraPermissions?: number[] | null;
}

export class Principal implements IPrincipal {
  firstName: string;
  lastName: string;
  emailAddress: string;
  role: number;
  defaultOperationId?: number | null;
  extraPermissions?: number[] | null;

  constructor(principal: IPrincipal) {
    this.firstName = principal.firstName;
    this.lastName = principal.lastName;
    this.emailAddress = principal.emailAddress;
    this.role = principal.role;
    this.defaultOperationId = principal.defaultOperationId;
    this.extraPermissions = principal.extraPermissions;
  }

  hasPermission(permission: Permission): boolean {
    if (this.role === Role.Admin) return true;

    if (!this.extraPermissions) return false;

    return this.extraPermissions.indexOf(permission) > -1;
  }

  hasAnyPermission(permissions: Permission[]): boolean {
    if (!permissions || permissions.length === 0) {
      return true;
    }
    let hasAtLestOne = false;
    for (const p of permissions || []) {
      if (this.hasPermission(p)) {
        hasAtLestOne = true;
      }
    }
    return hasAtLestOne;
  }
}