import { Typography, Button, SvgIcon, Card } from "@mui/material";
import { Box, Container, Stack } from "@mui/system";
import { RouterLink } from "src/shared/components/router-link";
import { Seo } from "src/shared/components/seo";
import PlusIcon from '@untitled-ui/icons-react/build/esm/Plus';
import { FC } from "react";

interface ListPageLayoutProps {
	title: string;
	addButtonPath?: string | undefined;
	addButtonAccessible?: boolean | undefined;
	children: React.ReactNode;
}
export const ListPageLayout: FC<ListPageLayoutProps> = (props) => {
	const { title, addButtonPath, addButtonAccessible, children } = props;
	return (
		<>
			<Seo title={title} />
			<Box
				component="main"
				sx={{
					flexGrow: 1,
					py: 3
				}}
			>
				<Container maxWidth={false}>
					<Stack spacing={4}>
						<Stack
							direction="row"
							justifyContent="space-between"
							spacing={4}
						>
							<Stack spacing={1}>
								<Typography variant="h4">
									{title}
								</Typography>
							</Stack>
							<Stack
								alignItems="center"
								direction="row"
								spacing={3}
							>
								{(addButtonPath !== undefined) && (
									<Button
										startIcon={(
											<SvgIcon>
												<PlusIcon />
											</SvgIcon>
										)}
										variant="contained"
										component={RouterLink}
										href={addButtonPath}
										disabled={!addButtonAccessible}
									>
										Add
									</Button>
								)}
							</Stack>
						</Stack>
						<Card>
							{children}
						</Card>
					</Stack>
				</Container>
			</Box>
		</>
	);
};