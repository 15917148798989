import { ChangeEvent, FC, MouseEvent, useState } from 'react';
import {
	Box,
	IconButton,
	Skeleton,
	SvgIcon,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TablePagination,
	TableRow
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { Scrollbar } from 'src/shared/components/scrollbar';
import { IpAddressRow } from 'src/models/ip-address';
import UIConfirmDialog from 'src/shared/components/ui/ui-confirm-dialog';

interface IpAddressTableProps {
	count?: number;
	items?: IpAddressRow[];
	onPageChange?: (event: MouseEvent<HTMLButtonElement> | null, newPage: number) => void;
	onRowsPerPageChange?: (event: ChangeEvent<HTMLInputElement>) => void;
	page?: number;
	rowsPerPage?: number;
	onDelete: (id: number) => Promise<void>;
}

const tableRowsLoading = (rows: number) => {
	return [...Array(rows)].map((row, index) => (
	  <TableRow key={index}>
		<TableCell>
		  <Skeleton animation="wave" variant="text" />
		</TableCell>
		<TableCell>
		  <Skeleton animation="wave" variant="text" />
		</TableCell>
		<TableCell width={150} align='right'>
			<Skeleton animation="wave" variant="text" />
		</TableCell>
	  </TableRow>
	));
  };

export const IpAddressTable: FC<IpAddressTableProps> = (props) => {
	const {
		count = 0,
		items = [],
		onPageChange = () => { },
		page = 0,
		onDelete
	} = props;

	const noRecords = count === 0;

	const [deleteRecord, setDeleteRecord] = useState<{id: number, name: string}>();

	const onDeleteConfirmed = async(id: number) => {
		await onDelete(id);
		setDeleteRecord(undefined);
	};

	const onDeleteCanceled = () => {
		setDeleteRecord(undefined);
	};

	return (
		<>
		<Box sx={{ position: 'relative' }}>

			<Scrollbar>
				<Table sx={{ minWidth: 700 }}>
					<TableHead>
						<TableRow>
							<TableCell>
								Ip Address
							</TableCell>
							<TableCell>
								Description
							</TableCell>
							<TableCell width={150} align='right'>
								Actions &nbsp; &nbsp;
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{noRecords ? page ===0 ? (<>
							{tableRowsLoading(5)}
						</>) : (
							<TableRow>
								<TableCell align='center' colSpan={3}>
									No records found.
								</TableCell>
							</TableRow>
						) : (<></>)}
						{items.map((row) => {
							return (
								<TableRow
									hover
									key={row.id}
								>
									<TableCell>
										{row.address}
									</TableCell>
									<TableCell>
										{row.description}
									</TableCell>
									<TableCell align="right">
										{/* <IconButton
											component={RouterLink}
											href={paths.admin.ipAddresses.update.replace(":ipAddressId", row.id)}
										>
											<SvgIcon>
												<ModeEditIcon />
											</SvgIcon>
										</IconButton> */}
										<IconButton
										onClick={() => setDeleteRecord({id: row.id, name: row.address})}
										>
											<SvgIcon>
												<DeleteIcon />
											</SvgIcon>
										</IconButton>
									</TableCell>
								</TableRow>
							);
						})}
					</TableBody>
				</Table>
			</Scrollbar>
			<TablePagination
				component="div"
				count={count}
				onPageChange={onPageChange}
				page={page}
				rowsPerPage={1}
				rowsPerPageOptions={[]}
			/>
		</Box>
		<UIConfirmDialog
			title="Delete IP Address?"
			record={deleteRecord}
			onCancel={onDeleteCanceled}
			onConfirm={onDeleteConfirmed}
		/>
		</>
	);
};

