
import { ApiQuery } from 'src/api/api-query';
import { Permission } from 'src/models/permission';
import LookupListPage from 'src/shared/pages/lookup-list-page';

const ConservatorListPage = () => {
	return (
		<LookupListPage 
			permissions={[Permission.DataManagementRead]}
			title="Conservators"
			pageSize={25}
			apiQuery={ApiQuery.GetConservators}
			addButtonPath={undefined}
		/>
	)
}

export default ConservatorListPage;
