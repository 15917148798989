import { Unstable_Grid2 as Grid, TextField } from "@mui/material";
import { FC } from "react";
import { UIFieldProps } from "./ui-field";

export enum UITextFieldType {
    Text,
    Password,
    Number
}

interface UITextFieldProps extends UIFieldProps{
    name: string;
    label: string;
    required: boolean
    multilineRows?: number
    type?: UITextFieldType
    gridSize?: number
}
const UITextField: FC<UITextFieldProps> = (props) => {
    const { name, label, required, formik, type = UITextFieldType.Text, multilineRows = 0, gridSize=12 } = props;
    return !formik ? (<></>): (
        <Grid
            xs={gridSize}
            md={gridSize}
            // sx={{display: gridSize < 12 ? 'flex' : 'block'}}
        >
            <TextField
                key={name}
                label={label}
                name={name}
                required={required}
                fullWidth
                multiline={multilineRows !== 0 }
                rows={multilineRows !== 0 ? multilineRows : undefined}
                error={!!(formik.touched[name] && formik.errors[name])}
                helperText={formik.touched[name] && formik.errors[name]}
                onBlur={formik.handleChange}
                onChange={formik.handleChange}
                value={formik.values[name] ?? ''}
                type={type === UITextFieldType.Password ? "password" : type === UITextFieldType.Number ? "number" : "text"}
                InputLabelProps={{ shrink: true }}
            />
        </Grid>
    );
};

export default UITextField;
