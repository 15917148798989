import { FC } from 'react';
import UIForm, { UIFormMode } from 'src/shared/components/ui/ui-form';
import UITextField, { UITextFieldType } from 'src/shared/components/ui/ui-text-field';
import { BaseArtifactDescriptionCommand } from 'src/api/commands/artifact-description';
import { nameof } from 'src/shared/utils/nameof';
import UISelectField, { UISelectFieldItem, UISelectFieldType } from 'src/shared/components/ui/ui-select-field';

export interface ArtifactDescriptionFormFields extends BaseArtifactDescriptionCommand {
  id?: number;
  isActive?: boolean;
  defaultDivisonEligibleNumber: number; //UIForm proxies selected number to boolean defaultDivisonEligible
}

interface ArtifactDescriptionFormDatasources {
  artifactTypes: UISelectFieldItem<number>[];
  conservators: UISelectFieldItem<number>[];
  artifactStatuses: UISelectFieldItem<number>[];
  concretionRemovals: UISelectFieldItem<number>[];
  protocols: UISelectFieldItem<number>[];
  chlorideRemovals: UISelectFieldItem<number>[];
  chlorideReleaseTests: UISelectFieldItem<number>[];
  chemicalStabilizations: UISelectFieldItem<number>[];
  dispositions: UISelectFieldItem<number>[];
  divisionEligibility: UISelectFieldItem<number>[];
}

export interface ArtifactDescriptionFormData {
  mode: UIFormMode;
  datasources: ArtifactDescriptionFormDatasources;
  fields: ArtifactDescriptionFormFields
}

interface ArtifactDescriptionFormProps {
  entityName: string;
  cancelHref: string;
  data: ArtifactDescriptionFormData;
  onSave: (mode: UIFormMode, data: ArtifactDescriptionFormFields) => Promise<number>;
  onCompleted: (id: number) => Promise<void>;
}

const ArtifactDescriptionForm: FC<ArtifactDescriptionFormProps> = (props) => {

  const { entityName, cancelHref, data: { mode, datasources, fields }, onSave, onCompleted } = props;

  const onBeforeSave = async (mode: UIFormMode, form: ArtifactDescriptionFormFields) => {
    form.defaultDivisionPoints = +(form.defaultDivisionPoints || 0);
    form.defaultDivisonEligible = form.defaultDivisonEligibleNumber === 1 ? true : false;
    return form;
  };

  return (
    <UIForm
      mode={mode}
      entity={entityName}
      cancelHref={cancelHref}
      onBeforeSave={onBeforeSave}
      onSave={onSave}
      onCompleted={onCompleted}
      fields={fields}>

      <UITextField
        name={nameof(fields, "name")}
        label="Name"
        required={true} />

      <UISelectField
        type={UISelectFieldType.Single}
        name={nameof(fields, "defaultDivisonEligibleNumber")}
        label="Default Division Eligibility"
        items={datasources.divisionEligibility}
        required={true} />

      <UITextField
        name={nameof(fields, "defaultDivisionPoints")}
        label="Default Division Points"
        type={UITextFieldType.Number}
        required={true} />

      <UISelectField
        type={UISelectFieldType.Single}
        name={nameof(fields, "defaultArtifactTypeId")}
        label="Default Artifact Type"
        items={datasources.artifactTypes}
        required={false} />

      <UISelectField
        type={UISelectFieldType.Single}
        name={nameof(fields, "defaultArtifactStatusId")}
        label="Default Artifact Status"
        items={datasources.artifactStatuses}
        required={false} />

      <UISelectField
        type={UISelectFieldType.Single}
        name={nameof(fields, "defaultDispositionId")}
        label="Default Disposition"
        items={datasources.dispositions}
        required={false} />

      <UISelectField
        type={UISelectFieldType.Single}
        name={nameof(fields, "defaultProtocolId")}
        label="Default Protocol"
        items={datasources.protocols}
        required={false} />

      <UISelectField
        type={UISelectFieldType.Single}
        name={nameof(fields, "defaultConservatorId")}
        label="Default Conservator"
        items={datasources.conservators}
        required={false} />

      <UISelectField
        type={UISelectFieldType.Single}
        name={nameof(fields, "defaultConcretionRemovalMethodId")}
        label="Default Concretion Removal"
        items={datasources.concretionRemovals}
        required={false} />

      <UISelectField
        type={UISelectFieldType.Single}
        name={nameof(fields, "defaultChlorideRemovalId")}
        label="Default Chloride Removal"
        items={datasources.chlorideRemovals}
        required={false} />

      <UISelectField
        type={UISelectFieldType.Single}
        name={nameof(fields, "defaultChlorideReleaseTestId")}
        label="Default Chloride Release Test"
        items={datasources.chlorideReleaseTests}
        required={false} />

      <UISelectField
        type={UISelectFieldType.Single}
        name={nameof(fields, "defaultChemicalStabilizationId")}
        label="Default Chemical Stabilization"
        items={datasources.chemicalStabilizations}
        required={false} />

    </UIForm>
  );
};

export default ArtifactDescriptionForm;