import { paths } from 'src/routes/paths';
import { Api } from 'src/api/api';
import { ApiCommand, CreateCommandResult, UpdateCommandResult } from "src/api/api-command";
import { CreateGalleonSectionCommand, UpdateGalleonSectionCommand } from 'src/api/commands/galleon-section';
import UISubPage from 'src/shared/components/ui/ui-sub-page';
import { useNavigate, useParams } from 'react-router-dom';
import UIForm, { UIFormMode } from 'src/shared/components/ui/ui-form';
import { FC, useEffect, useState } from 'react';
import { GetGalleonSectionQuery, GetGalleonSectionQueryResult } from 'src/api/queries/galleon-section/get-galleon-section';
import { ApiQuery } from 'src/api/api-query';
import { GetLookupRowsQuery, GetLookupRowsQueryResult } from 'src/api/queries/lookup/get-lookup-rows';
import toast from 'react-hot-toast';
import { UIProgress } from 'src/shared/components/ui/ui-progress';
import { UISelectFieldItem } from 'src/shared/components/ui/ui-select-field';
import UITextField from 'src/shared/components/ui/ui-text-field';
import { nameof } from 'src/shared/utils/nameof';
import UIStatusRadioField, { UIStatusRadioFieldValue } from 'src/shared/components/ui/ui-status-radio-field';
import { Permission } from 'src/models/permission';
import { useAuth } from 'src/shared/hooks/use-auth';



interface GalleonSectionFormFields {
  id?: number;
  name: string;
  description?: string;
  isActive?: UIStatusRadioFieldValue;
}

interface GalleonSectionFormDatasources {
  artifactTypes: UISelectFieldItem<number>[];
}

interface GalleonSectionFormData {
  mode: UIFormMode;
  datasources: GalleonSectionFormDatasources;
  fields: GalleonSectionFormFields
}

interface GalleonSectionFormProps {
  entityName: string;
  cancelHref: string;
  data: GalleonSectionFormData;
  onSave: (mode: UIFormMode, data: GalleonSectionFormFields) => Promise<number>;
  onCompleted: (id: number) => Promise<void>;
}

const GalleonSectionForm: FC<GalleonSectionFormProps> = (props) => {

  const { entityName, cancelHref, data: { mode, fields }, onSave, onCompleted } = props;

  const onBeforeSave = async (mode: UIFormMode, form: GalleonSectionFormFields) => {
    return form;
  };

  return (
    <UIForm
      mode={mode}
      entity={entityName}
      cancelHref={cancelHref}
      onBeforeSave={onBeforeSave}
      onSave={onSave}
      onCompleted={onCompleted}
      fields={fields}>

      <UITextField
        name={nameof(fields, "name")}
        label="Name"
        required={true} />

      <UITextField
        name={nameof(fields, "description")}
        label="Description"
        multilineRows={5}
        required={false} />

      {mode === UIFormMode.Create  ? (<></>) : (

        <UIStatusRadioField
          name={nameof(fields, "isActive")}
          label="Status"
          required={true}
        />

      )}


    </UIForm>
  );
};

const GalleonSectionManagePage = () => {
  const entityName = "Galleon Section";
  const entityListPath = paths.admin.galleonSections.list;
  const [pageTitle, setPageTitle] = useState<string>("");
  const [formData, setFormData] = useState<GalleonSectionFormData>();
  const { id } = useParams();
  const navigate = useNavigate();
  const auth = useAuth();

  const onSave = async (mode: UIFormMode, fields: GalleonSectionFormFields): Promise<number> => {

    if (mode === UIFormMode.Update) {
      const command: UpdateGalleonSectionCommand = {
        ...fields,
        id: fields.id!,
        isActive: fields.isActive === UIStatusRadioFieldValue.Active ? true : false
      };

      await Api.executeCommand<UpdateGalleonSectionCommand, UpdateCommandResult>(
        ApiCommand.UpdateGalleonSection,
        command);

      return command.id;

    } else {
      const { id, isActive, ...createFields } = fields;
      const command: CreateGalleonSectionCommand = {
        ...createFields,
      };

      const result = await Api.executeCommand<CreateGalleonSectionCommand, CreateCommandResult>(
        ApiCommand.CreateGalleonSection,
        command);

      return result.id;
    }

  };

  const onCompleted = async (id: number) => {
    navigate(paths.admin.galleonSections.list)
  }

  useEffect(() => {
    let aborted = false;
    const idNumber = +(id || 0); //Router Param
    const mode: UIFormMode = idNumber ? UIFormMode.Update : UIFormMode.Create;
    const permission = (mode === UIFormMode.Create) ? Permission.DataManagementCreate : Permission.DataManagementEdit;    
    if (!auth.authState.principal?.hasPermission(permission)) {
      navigate(paths.error403);
      return;
    }

    async function getLookupSelectFieldItems(apiQuery: ApiQuery): Promise<UISelectFieldItem<number>[]> {
      const result = await Api.executeQuery<GetLookupRowsQuery, GetLookupRowsQueryResult>(apiQuery, {});
      const rows = result?.rows.map<UISelectFieldItem<number>>(r => ({ label: r.name, value: r.id })) || [];
      rows.unshift({ value: -1, label: "< None >" })
      return rows;
    }

    async function onPageLoad() {
      try {
        let record: GalleonSectionFormFields;
        setPageTitle(`${entityName} > ${UIFormMode[mode]}`);

        if (mode === UIFormMode.Update) {

          const result = await Api.executeQuery<GetGalleonSectionQuery, GetGalleonSectionQueryResult>(
            ApiQuery.GetGalleonSection, { id: idNumber }
          );

          const { isActive, ...fields } = result.record
          record = {
            ...fields,
            isActive: isActive ? UIStatusRadioFieldValue.Active : UIStatusRadioFieldValue.Inactive
          };

        } else {
          record = {
            name: ""
          }
        }

        //Will use this or descriptions in the future
        const artifactTypes = await getLookupSelectFieldItems(ApiQuery.GetArtifactTypes);


        if (aborted)
          return;

        setFormData({
          mode,
          fields: { ...record },
          datasources: {
            artifactTypes
          }
        });


      } catch (err) {
        console.log(err);
        toast.error(`${entityName} could not be loaded.`);
        navigate(paths.admin.galleonSections.list)
      }

    }

    onPageLoad();

    return () => {
      aborted = true;
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);



  return (
    <UISubPage
      title={pageTitle}
      backTitle={entityName}
      backHref={entityListPath}>
      {!formData ? (
        <UIProgress />
      ) : (
        <GalleonSectionForm
          entityName={entityName}
          cancelHref={entityListPath}
          onSave={onSave}
          onCompleted={onCompleted}
          data={formData} />
      )}
    </UISubPage>
  );
};

export default GalleonSectionManagePage;
