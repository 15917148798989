import { FormControl, FormHelperText, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import { FC, useState } from 'react';
import { Role } from 'src/models/role';
import { paths } from 'src/routes/paths';
import UIForm, { UIFormMode } from 'src/shared/components/ui/ui-form';
import UITextField from 'src/shared/components/ui/ui-text-field';
import { Unstable_Grid2 as Grid } from "@mui/material";
import { MultiFactorAuthenticationMethod } from 'src/models/mfa';
import { PermissionSelector } from 'src/pages/admin/user/components/permission-selector';
import UISelectField, { UISelectFieldItem, UISelectFieldType } from 'src/shared/components/ui/ui-select-field';
import { nameof } from 'src/shared/utils/nameof';


interface UserFormDatasources {
  operations: UISelectFieldItem<number>[];
}

export interface UserFormData {
  mode: UIFormMode;
  datasources: UserFormDatasources;
  fields: UserFormFields
}


export interface UserFormFields {
  id: number;
  firstName: string;
  lastName: string;
  emailAddress: string;
  role: Role;
  isActive: boolean;
  phoneNumber: string | null;
  defaultOperationId?: number | null;
  mfaMethod: MultiFactorAuthenticationMethod | null;
  extraPermissions: number[] | null;
}

interface UserFormProps {
  data: UserFormData;
  onSave: (mode: UIFormMode, fields: UserFormFields) => Promise<number>;
  onCompleted: (id: number) => Promise<void>;
}


export const UserForm: FC<UserFormProps> = (props) => {
  const { data: { mode, datasources, fields }, onSave, onCompleted } = props;
  const [role, setRole] = useState<Role>(fields.role);
  const [mfaMethod, setMfaMethod] = useState<MultiFactorAuthenticationMethod | null>(fields.mfaMethod || MultiFactorAuthenticationMethod.None);
  const [selectedExtraPermissions, setSelectedExtraPermissions] = useState<number[]>(fields.extraPermissions || []);

  const onBeforeSave = async (mode: UIFormMode, fields: UserFormFields) => {
    fields.role = role;
    fields.mfaMethod = mfaMethod;
    fields.phoneNumber = !fields.phoneNumber ? null : fields.phoneNumber;
    fields.extraPermissions = role === Role.Admin ? [] : selectedExtraPermissions;
    return fields;
  };

  return (
    <UIForm
      mode={mode}
      entity="User"
      cancelHref={paths.admin.users.list}
      onBeforeSave={onBeforeSave}
      onSave={onSave}
      onCompleted={onCompleted}
      fields={fields}>

      <UITextField
        name="firstName"
        label="First Name"
        required={false} />

      <UITextField
        name="lastName"
        label="Last Name"
        required={false} />
      <UITextField
        name="emailAddress"
        label="Email Address (Username)"
        required={false} />
      <UITextField
        name="phoneNumber"
        label="Phone Number"
        required={false} />

      <Grid
        xs={12}
        md={12}
      >
        <FormControl sx={{ minWidth: "100%" }}>
          <InputLabel sx={{ pb: 2 }} id="mfaMethod_label">&nbsp;Multifactor Authentication</InputLabel>
          <Select
            name="mfaMethod"
            value={mfaMethod}
            labelId='mfaMethod_label'
            label="MFA Method"
            fullWidth={true}
            onChange={(p) => setMfaMethod(p.target.value as MultiFactorAuthenticationMethod)}
          >
            <MenuItem value={MultiFactorAuthenticationMethod.None}>None</MenuItem>
            <MenuItem value={MultiFactorAuthenticationMethod.SMS}>SMS - Text Message</MenuItem>
          </Select>
          <FormHelperText>Phone number must be a cell phone for SMS.</FormHelperText>
        </FormControl>
      </Grid>
      <Grid
        xs={12}
        md={12}
      >
        <FormControl sx={{ minWidth: "100%" }}>
          <InputLabel sx={{ pb: 2 }} id="role_label">&nbsp;Role</InputLabel>
          <Select
            name="role"
            value={role}
            labelId='role_label'
            label="Role"
            fullWidth={true}
            onChange={(p) => setRole(p.target.value as Role)}
          >
            <MenuItem value={Role.Admin}>Administrator</MenuItem>
            <MenuItem value={Role.User}>User</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <UISelectField
        type={UISelectFieldType.Single}
        name={nameof(fields, "defaultOperationId")}
        label="Default Operation"
        items={datasources.operations}
        required={false} />
      <Grid
        xs={12}
        md={12}
      >
        <Typography
          gutterBottom
          variant="subtitle1"
        >
          Permissions
        </Typography>
        <Typography
          color="text.secondary"
          variant="body2"
        >
          {role === Role.Admin ?
            (<>Administrators have all permissions.</>) :
            (<>Users must be granted permissions to the resources they are authorized to access.</>)
          }
        </Typography>
      </Grid>

      {role === Role.Admin ? (<></>) : (
        <PermissionSelector initialPermissions={fields.extraPermissions} onPermissionsChanged={(value) => setSelectedExtraPermissions(value)} />
      )}


    </UIForm>
  );
};


