import type { FC } from 'react';
import { useRoutes } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { RTL } from './shared/components/rtl';
import { SplashScreen } from './shared/components/splash-screen';
import { Toaster } from './shared/components/toaster';
import { SettingsConsumer, SettingsProvider } from './shared/contexts/settings-context';
import { AuthConsumer, AuthProvider } from './shared/contexts/auth-context';
import { useNprogress } from './shared/hooks/use-nprogress';
import { routes } from './routes/routes';
import { createTheme } from './shared/theme';
// Remove if react-quill is not used
// import 'react-quill/dist/quill.snow.css';
// Remove if react-draft-wysiwyg is not used
// import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
// Remove if simplebar is not used
import 'simplebar-react/dist/simplebar.min.css';


export const App: FC = () => {
  useNprogress();

  const element = useRoutes(routes);

  return (
        <AuthProvider>
          <AuthConsumer>
            {(auth) => (
              <SettingsProvider>
                <SettingsConsumer>
                  {(settings) => {
                    // Prevent theme flicker when restoring custom settings from browser storage
                    if (!settings.isInitialized) {
                      // return null;
                    }

                    const theme = createTheme({
                      colorPreset: settings.colorPreset,
                      contrast: settings.contrast,
                      direction: settings.direction,
                      paletteMode: settings.paletteMode,
                      responsiveFontSizes: settings.responsiveFontSizes
                    });

                    // Prevent guards from redirecting
                    const showSlashScreen = !auth.authState.isInitialized;

                    return (
                      <ThemeProvider theme={theme}>
                        <Helmet>
                          <meta
                            name="color-scheme"
                            content={settings.paletteMode}
                          />
                          <meta
                            name="theme-color"
                            content={theme.palette.neutral[900]}
                          />
                        </Helmet>
                        <RTL direction={settings.direction}>
                          <CssBaseline />
                          {
                            showSlashScreen
                              ? <SplashScreen />
                              : (
                                <>
                                  {element}
                                </>
                              )
                          }
                          <Toaster />
                        </RTL>
                      </ThemeProvider>
                    );
                  }}
                </SettingsConsumer>
              </SettingsProvider>
            )}
          </AuthConsumer>
        </AuthProvider>
  );
};
