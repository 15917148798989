import { Alert, AlertTitle, Unstable_Grid2 as Grid } from "@mui/material";
import { FC, useEffect } from "react";

interface UIMessageProps {
    messages: string[] | undefined
    type: 'success' | 'info' | 'warning' | 'error';
    onClose?: () => void;
}

const UIMessage: FC<UIMessageProps> = (props) => {
    const { messages, onClose, type } = props;
    const title = type === 'success' ? 'Success' : type === 'info' ? 'Information' : type === 'warning' ? 'Warning' : 'Error';

    useEffect(()=>{
        if(messages && messages.length > 0) {
            window.scrollTo(0, 0)
        }
       
    },[messages]);

    return messages && messages.length > 0 ? (
            <Grid
                xs={12}
                md={12}
                mb={2}
            >
                <Alert 
                    severity={type} 
                    onClose={onClose}>
                    <AlertTitle>{title}</AlertTitle>
                    <ul>
                        {messages.map((e,i) => <li key={i}>{e}</li>)}
                    </ul>
                </Alert>
            </Grid>
    ) : (<></>);
};

export default UIMessage;