export interface ArtifactRow {
    artifactClassName?: string;
    artifactDescriptionName?: string;
    artifactLocationName?: string;
    artifactStatusName?: string;
    artifactTypeName?: string;
    conservationBatch?: number;
    divisible?: boolean;
    dispositionName?: string;
    hole?: string;
    highTicket?: boolean;
    id: number; 
    investor?: string;
    lastModifiedDate?: Date;
    lastModifiedBy?: string;
    latitude?: number;
    longitude?: number;
    latitudeDecimalDegrees?: number;
    longitudeDecimalDegrees?: number;
    operationName?: string;
    ownerName?: string;
    points?: string;
    quantity?: number;
    recoveryDate?: Date;
    securitySticker?: string;
    tag?: string;
    value?: number;
    vesselName?: string;
    year?: number;
    wreckSiteName?: string;
  }
  
  export enum ArtifactTagSearchMode {
    ExactMatch = 10,
    StartsWith = 20,
    EndsWith = 30,
    Contains = 40
  }
  export interface ArtifactModel {
    artifactClassId?: number;
    artifactClassName?: string;
    artifactDescriptionId?: number;
    artifactDescriptionName?: string;
    artifactLocationId?: number;
    artifactLocationName?: string;
    artifactStatusId?: number;
    artifactStatusName?: string;
    artifactTypeId?: number;
    artifactTypeName?: string;
    assayerCertainty?: boolean;
    assayerId?: number;
    assayerName?: string;
    carat?: number;
    certificateComments?: string;
    certificatePrintedDate?: Date;
    classFactor?: string;
    clInDate?: Date;
    chlorideReleaseTestId?: number;
    chlorideReleaseTestName?: string;
    chlorideRemovalId?: number;
    chlorideRemovalName?: string;
    coinDate?: string;
    coinDateRemarkId?: number;
    coinDateRemarkName?: string;
    coinNumber?: string;
    concretionRemovalId?: number;
    concretionRemovalName?: string;
    conservatorId?: number;
    conservatorName?: string;
    comments?: string;
    conservationBatch?: number;
    conservationResultId?: number;
    conservationResultName?: string;
    curatorDispositionDate?: Date;
    curatorDispositionId?: number;
    curatorDispositionName?: string;
    dateMark?: string;
    denominationId?: number;
    denominationName?: string;
    divisible?: boolean;
    fcMarks?: number;
    fgMarks?: number;
    fiMarks?: number;
    flippit1?: string;
    flippit2?: string;
    frMarks?: number;
    fsMarks?: number;
    fuMarks?: number;
    fvMarks?: number;
    gradeId?: number;
    gradeName?: string;
    highTicket?: boolean;
    hole?: string;
    id: number;
    internalComments?: string;
    inDate?: Date;
    investor?: string;
    kStamps?: number;
    karats?: string;
    lastModifiedBy?: string;
    lastModifiedDate?: Date;
    latitude?: number;
    latitudeDecimalDegrees?: number;
    longitude?: number;
    longitudeDecimalDegrees?: number;
    manifest?: string;
    measurements?: string;
    mintId?: number;
    mintName?: string;
    originallyRecoveredBy?: string;
    operationId?: number;
    operationName?: string;
    ownerId?: number;
    ownerName?: string;
    pearlGradeId?: number;
    pearlGradeName?: string;
    points?: string;
    postHeightCm?: string;
    postLengthCm?: string;
    postWeightGm?: string;
    postWidthCm?: string;
    preHeightCm?: string;
    preLengthCm?: string;
    preWeightGm?: string;
    preWidthCm?: string;
    protocolFollowed1?: number;
    protocolFollowedOld?: number;
    protocolId?: number;
    protocolName?: string;
    quantity?: number;
    recoveryDate?: Date;
    reignId?: number;
    reignName?: string;
    recordUpdated?: boolean;
    removedDate?: Date;
    scCoinOrigin?: string;
    scComment?: string;
    scConsolidated?: string;
    scDescription?: string;
    scDescription2?: string;
    scDiv2?: string;
    scLocation?: string;
    scOrigInv?: string;
    scOrigin2?: string;
    scType?: string;
    securitySticker?: string;
    searchRadius?: number;
    tag?: string;
    tStamps?: number;
    tsiNumber?: string;
    tradable?: boolean;
    value?: number;
    vesselId?: number;
    vesselName?: string;
    waterDepth?: number;
    weightTroyOunces?: string;
    weightTroyPounds?: string;
    wreckSiteId?: number;
    wreckSiteName?: string;
    year?: number;
    chemicalStabilizationId?: number;
    chemicalStabilizationName?: string;
    clOutDate?: Date;
    chemInDate?: Date;
    chemOutDate?: Date;
    dispositionId?: number;
    dispositionName?: string;
  }
  
