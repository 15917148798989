import type { ChangeEvent, FC, MouseEvent } from 'react';
import Edit02Icon from '@untitled-ui/icons-react/build/esm/Edit02';
import {
	Avatar,
	Box,
	IconButton,
	Link,
	Skeleton,
	Stack,
	SvgIcon,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TablePagination,
	TableRow,
	Typography
} from '@mui/material';
import { RouterLink } from 'src/shared/components/router-link';
import { Scrollbar } from 'src/shared/components/scrollbar';
import { paths } from 'src/routes/paths';
import { UserRow } from 'src/models/user';
import { getInitials } from 'src/shared/utils/get-initials';
import { Role } from 'src/models/role';

interface UserListTableProps {
	count?: number;
	items?: UserRow[];
	onPageChange?: (event: MouseEvent<HTMLButtonElement> | null, newPage: number) => void;
	onRowsPerPageChange?: (event: ChangeEvent<HTMLInputElement>) => void;
	page?: number;
	rowsPerPage?: number;
	selected?: string[];
}

const tableRowsLoading = (rows: number) => {
	return [...Array(rows)].map((row, index) => (
	  <TableRow key={index}>
		<TableCell>
		  <Skeleton animation="wave" variant="text" />
		</TableCell>
		<TableCell>
		  <Skeleton animation="wave" variant="text" />
		</TableCell>
		<TableCell>
		  <Skeleton animation="wave" variant="text" />
		</TableCell>
		<TableCell width={150} align='right'>
			<Skeleton animation="wave" variant="text" />
		</TableCell>
	  </TableRow>
	));
  };
  
export const UserListTable: FC<UserListTableProps> = (props) => {
	const {
		count = 0,
		items = [],
		onPageChange = () => { },
		onRowsPerPageChange,
		page = 0,
		rowsPerPage = 0
	} = props;

	const noRecords = count === 0;

	return (
		<Box sx={{ position: 'relative' }}>
		
			<Scrollbar>
				<Table sx={{ minWidth: 700 }}>
					<TableHead>
						<TableRow>
							<TableCell>
								User
							</TableCell>
							<TableCell>
								Role
							</TableCell>
							<TableCell>
								Status
							</TableCell>
							<TableCell width={150} align='right'>
								Actions &nbsp; &nbsp;
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{noRecords ? page ===0 ? (<>
							{tableRowsLoading(5)}
						</>) : (
							<TableRow>
								<TableCell align='center' colSpan={3}>
									No records found.
								</TableCell>
							</TableRow>
						) : (<></>)}
						{items.map((user) => {
							return (
								<TableRow
									hover
									key={user.id}
								>
				
									<TableCell>
										<Stack
											alignItems="center"
											direction="row"
											spacing={1}
										>
											<Avatar>{getInitials(user.firstName + ' ' + user.lastName)}</Avatar>
											
											<div>
												<Link
													color="inherit"
													component={RouterLink}
													// href={paths.admin.users.view.replace(":id",user.id?.toString())}
													href={paths.admin.users.update.replace(":userId",user.id?.toString())}
													variant="subtitle2"
												>
													{user.firstName} {user.lastName}
												</Link>
												<Typography
													color="text.secondary"
													variant="body2"
												>
													{user.emailAddress}
												</Typography>
											</div>
										</Stack>
									</TableCell>
									<TableCell>
										{user.role === Role.Admin ? "Admin" : "User" }
									</TableCell>
									<TableCell>
										{user.isActive ? "Active" : "Inactive" }
									</TableCell>
									<TableCell align="right">
										<IconButton
											component={RouterLink}
											href={paths.admin.users.update.replace(":userId",user.id?.toString())}
										>
											<SvgIcon>
												<Edit02Icon />
											</SvgIcon>
										</IconButton>
										{/* <IconButton
											component={RouterLink}
											href={paths.admin.users.view.replace(":userId",user.id)}
										>
											<SvgIcon>
												<ArrowRightIcon />
											</SvgIcon>
										</IconButton> */}
									</TableCell>
								</TableRow>
							);
						})}
					</TableBody>
				</Table>
			</Scrollbar>
			<TablePagination
				component="div"
				count={count}
				onPageChange={onPageChange}
				onRowsPerPageChange={onRowsPerPageChange}
				page={page}
				rowsPerPage={rowsPerPage}
				rowsPerPageOptions={[50, 100, 500]}
			/>
		</Box>
	);
};
