import { paths } from 'src/routes/paths';
import { Api } from 'src/api/api';
import { ApiCommand } from "src/api/api-command";
import { IpAddressForm, IpAddressFormData } from 'src/pages/admin/ip-address/components/ip-address-form';
import { CreateIpAddressCommand, CreateIpAddressCommandResult } from 'src/api/commands/ip-address';
import UISubPage from 'src/shared/components/ui/ui-sub-page';
import { useNavigate } from 'react-router-dom';
import { UIFormMode } from 'src/shared/components/ui/ui-form';
import { useMount } from 'src/shared/hooks/use-mount';
import { useAuth } from 'src/shared/hooks/use-auth';
import { Permission } from 'src/models/permission';

const IpAddressCreatePage = () => {
  const navigate = useNavigate();
  const auth = useAuth();

  useMount(()=>{
    if (!auth.authState.principal?.hasPermission(Permission.Administration)) {
      navigate(paths.error403);
      return;
    }
  })

  const onSave = async (mode: UIFormMode, data: IpAddressFormData) => {
    const command: CreateIpAddressCommand = {
      address: data.address,
      description: data.description
    };

    var result = await Api.executeCommand<CreateIpAddressCommand, CreateIpAddressCommandResult>(
      ApiCommand.CreateIpAddress,
      command);

    return result.id;
  };

  const onCompleted = async (id: string) => {
    navigate(paths.admin.ipAddresses.list)
  }

  const formData: IpAddressFormData = {
    address: "",
    description: ""
  }

  return (
    <UISubPage
      title="Admin > Ip Addresses > Create"
      backTitle="Ip Addresses"
      backHref={paths.admin.ipAddresses.list}>
      <IpAddressForm 
        mode={UIFormMode.Create}
        onSave={onSave} 
        onCompleted={onCompleted} 
        data={formData} />
    </UISubPage>
  );
};

export default IpAddressCreatePage;
