
import { GridColDef } from '@mui/x-data-grid';
import { ListPageLayout } from 'src/shared/layouts/page/list-page-layout';
import { UIDataGrid } from 'src/shared/components/ui/ui-data-grid';
import { useState } from 'react';
import { useMount } from 'src/shared/hooks/use-mount';
import { Api } from 'src/api/api';
import { ApiQuery } from 'src/api/api-query';
import UIMessage from 'src/shared/components/ui/ui-message';
import { GetSafesQuery, GetSafesQueryResult } from 'src/api/queries/safe/get-safes';
import { paths } from 'src/routes/paths';
import { nameof } from 'src/shared/utils/nameof';
import { SafeRow } from 'src/models/safe';
import { activeInactiveColumn, editActionColumn, idColumn, textColumn } from 'src/shared/components/grid-columns';
import { useAuth } from 'src/shared/hooks/use-auth';
import { useNavigate } from 'react-router';
import { Permission } from 'src/models/permission';

const SafeListPage = () => {
	const auth = useAuth();
    const navigate = useNavigate();
	const title = "Safes";
	const pageSize =  25;
	const addButtonPath = paths.admin.safes.create;
	const addButtonAccessible = auth.authState.principal?.hasPermission(Permission.DataManagementCreate) || false;
    const editButtonAccessible = auth.authState.principal?.hasPermission(Permission.DataManagementEdit) || false;
	
    const loadingRows: any[] = ((rows: number) => {
        return [...Array(rows)].map((row, index) => {
            return {
                id: Number.MAX_SAFE_INTEGER - index,
                name: null,
                isActive: null,
            }
        });
    })(pageSize);
    

    const columns: GridColDef[] = [
        idColumn(pageSize,nameof<SafeRow>("id")),
		textColumn(nameof<SafeRow>("buildingName"),"Building",0.20),
        textColumn(nameof<SafeRow>("name"),"Name",0.20),
		textColumn(nameof<SafeRow>("description"),"Description",0.30),
        activeInactiveColumn(nameof<SafeRow>("isActive"),"Status"),
        editActionColumn(editButtonAccessible, id => paths.admin.safes.update.replace(":id",id))
    ];
	const [rows, setRows] = useState<any[]>(loadingRows);
	const [loading, setLoading] = useState<boolean>(true);
	const [errors, setErrors] = useState<string[]>([]);

	useMount(() => {
		if(!auth.authState.principal?.hasPermission(Permission.DataManagementRead)) {
            navigate(paths.error403);
            return;
        }

		const loadData = async () => {
			try {
				const result = await Api.executeQuery<GetSafesQuery, GetSafesQueryResult>(
					ApiQuery.GetSafes, {});
				setRows(result.rows);
				setLoading(false);
			} catch (err) {
				setErrors(err.response.data.errors || [err.response.statusText])
				setLoading(false);
			}
		}

		

		loadData();
	});

	return (
		<ListPageLayout
			title={title}
			addButtonPath={addButtonPath}
			addButtonAccessible={addButtonAccessible}>
			<UIMessage
				type="error"
				messages={errors}
				onClose={() => setErrors([])} />
            <div style={{ width: '100%'}}>
			<UIDataGrid
				loading={loading}
				hideFooterPagination={loading}
				rows={rows}
				columns={columns}
			/>
             </div>
		</ListPageLayout>
	);
};

export default SafeListPage;

