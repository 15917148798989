export const formatDateAsYYYYMMDD = (date: Date): string => {
    const year = date.getFullYear();
    // JavaScript's getMonth() returns 0 for January, 11 for December, so add 1 to get the correct month number
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    
    return `${year}-${month}-${day}`;
}

export const formatAsDate = (value: any) => {
    if(!value || value === '') return '';

    try {
        var d = new Date(value);
        return formatDateAsYYYYMMDD(d);
    }catch {
        return value;
    }
}