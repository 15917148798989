import { paths } from 'src/routes/paths';
import { Api } from 'src/api/api';
import { ApiCommand, CreateCommandResult, UpdateCommandResult } from "src/api/api-command";
import { CreateArtifactDescriptionCommand, UpdateArtifactDescriptionCommand } from 'src/api/commands/artifact-description';
import UISubPage from 'src/shared/components/ui/ui-sub-page';
import { useNavigate, useParams } from 'react-router-dom';
import { UIFormMode } from 'src/shared/components/ui/ui-form';
import ArtifactDescriptionForm, { ArtifactDescriptionFormData, ArtifactDescriptionFormFields } from './components/form';
import { useState } from 'react';
import { GetArtifactDescriptionQuery, GetArtifactDescriptionQueryResult } from 'src/api/queries/artifact-description/get-artifact-description';
import { ApiQuery } from 'src/api/api-query';
import { GetLookupRowsQuery, GetLookupRowsQueryResult } from 'src/api/queries/lookup/get-lookup-rows';
import toast from 'react-hot-toast';
import { UIProgress } from 'src/shared/components/ui/ui-progress';
import { UISelectFieldItem } from 'src/shared/components/ui/ui-select-field';
import { useMount } from 'src/shared/hooks/use-mount';
import { Permission } from 'src/models/permission';
import { useAuth } from 'src/shared/hooks/use-auth';


const ArtifactDescriptionManagePage = () => {
  const entityName = "Artifact Description";
  const entityListPath = paths.admin.artifactDescriptions.list;
  const [pageTitle, setPageTitle] = useState<string>("");
  const [formData, setFormData] = useState<ArtifactDescriptionFormData>();
  const { id } = useParams();
  const navigate = useNavigate();
  const auth = useAuth();
  
  const onSave = async (mode: UIFormMode, fields: ArtifactDescriptionFormFields): Promise<number> => {

    if (mode === UIFormMode.Update) {
      const command: UpdateArtifactDescriptionCommand = {
        ...fields,
        id: fields.id!,
        isActive: fields.isActive!
      };

      await Api.executeCommand<UpdateArtifactDescriptionCommand, UpdateCommandResult>(
        ApiCommand.UpdateArtifactDescription,
        command);

      return command.id;

    } else {
      const { id, isActive, ...createFields } = fields;
      const command: CreateArtifactDescriptionCommand = {
        ...createFields,
      };

      const result = await Api.executeCommand<CreateArtifactDescriptionCommand, CreateCommandResult>(
        ApiCommand.CreateArtifactDescription,
        command);

      return result.id;
    }

  };

  const onCompleted = async (id: number) => {
    navigate(paths.admin.artifactDescriptions.list)
  }

  useMount(() => {
    let aborted = false;
    const idNumber = +(id || 0); //Router Param
    const mode: UIFormMode = idNumber ? UIFormMode.Update : UIFormMode.Create;
    const permission = (mode === UIFormMode.Create) ? Permission.DataManagementCreate : Permission.DataManagementEdit;    
    if (!auth.authState.principal?.hasPermission(permission)) {
      navigate(paths.error403);
      return;
    }

    async function getLookupSelectFieldItems(apiQuery: ApiQuery): Promise<UISelectFieldItem<number>[]> {
      const result = await Api.executeQuery<GetLookupRowsQuery, GetLookupRowsQueryResult>(apiQuery, {});
      const rows = result?.rows.map<UISelectFieldItem<number>>(r => ({ label: r.name, value: r.id })) || [];
      rows.unshift({value: -1, label: "< None >"})
      return rows;
    }

    async function onPageLoad() {
      try {
        let record: ArtifactDescriptionFormFields;
        setPageTitle(`${entityName} > ${UIFormMode[mode]}`);
        if (mode === UIFormMode.Update) {
          const result = await Api.executeQuery<GetArtifactDescriptionQuery, GetArtifactDescriptionQueryResult>(
            ApiQuery.GetArtifactDescription, { id: idNumber }
          );
          record = {
            ...result.record,
            defaultDivisonEligibleNumber: result.record.defaultDivisonEligible ? 1 : 0, //UI is select
          }
        } else {
          record = {
            name: "",
            defaultDivisionPoints: 0,
            defaultDivisonEligible: false,
            defaultDivisonEligibleNumber: 0,
          }
        }

        const artifactTypes = await getLookupSelectFieldItems(ApiQuery.GetArtifactTypes);
        const conservators = await getLookupSelectFieldItems(ApiQuery.GetConservators);
        const artifactStatuses = await getLookupSelectFieldItems(ApiQuery.GetArtifactStatuses);
        const concretionRemovals = await getLookupSelectFieldItems(ApiQuery.GetConcretionRemovalMethods);
        const protocols = await getLookupSelectFieldItems(ApiQuery.GetProtocols);
        const chlorideRemovals = await getLookupSelectFieldItems(ApiQuery.GetChlorideRemovals);
        const chlorideReleaseTests = await getLookupSelectFieldItems(ApiQuery.GetChlorideReleaseTests);
        const chemicalStabilizations = await getLookupSelectFieldItems(ApiQuery.GetChemicalStabilizations);
        const dispositions = await getLookupSelectFieldItems(ApiQuery.GetDispositions);
        const divisionEligibility = [
          {label: "Eligible", value: 1,},
          {label: "Not Eligible", value: 0}
        ]

        if (aborted)
          return;

        setFormData({
          mode,
          fields: { ...record },
          datasources: {
            artifactTypes,
            conservators,
            artifactStatuses,
            concretionRemovals,
            protocols,
            chlorideRemovals,
            chlorideReleaseTests,
            chemicalStabilizations,
            dispositions,
            divisionEligibility
          }
        });


      } catch (err) {
        console.log(err);
        toast.error(`${entityName} could not be loaded.`);
        navigate(paths.admin.artifactDescriptions.list)
      }

    }

    onPageLoad();

    return () => {
      aborted = true;
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  });



  return (
    <UISubPage
      title={pageTitle}
      backTitle={entityName}
      backHref={entityListPath}>
      {!formData ? (
        <UIProgress/>
      ) : (
        <ArtifactDescriptionForm
          entityName={entityName}
          cancelHref={entityListPath}
          onSave={onSave}
          onCompleted={onCompleted}
          data={formData} />
      )}
    </UISubPage>
  );
};

export default ArtifactDescriptionManagePage;
