import { paths } from 'src/routes/paths';
import { Api } from 'src/api/api';
import { ApiCommand } from "src/api/api-command";
import { CreateUserCommand, CreateUserCommandResult } from 'src/api/commands/user';
import UISubPage from 'src/shared/components/ui/ui-sub-page';
import { useNavigate } from 'react-router-dom';
import { UIFormMode } from 'src/shared/components/ui/ui-form';
import { UserForm, UserFormData, UserFormFields } from 'src/pages/admin/user/components/user-form';
import { Role } from 'src/models/role';
import { MultiFactorAuthenticationMethod } from 'src/models/mfa';
import { Permission } from 'src/models/permission';
import { useAuth } from 'src/shared/hooks/use-auth';
import { useMount } from 'src/shared/hooks/use-mount';
import { ApiQuery } from 'src/api/api-query';
import { getLookupSelectFieldItems, LookupSelectFieldItemLabel } from 'src/shared/utils/get-lookup-select-items';
import { useState } from 'react';
import { UIProgress } from 'src/shared/components/ui/ui-progress';


const UserCreatePage = () => {
  const navigate = useNavigate();
  const auth = useAuth();
  const [formData, setFormData] = useState<UserFormData>()

  useMount(() => {
    let aborted = false;
    if (!auth.authState.principal?.hasPermission(Permission.Administration)) {
      navigate(paths.error403);
      return;
    }


    async function loadPageData() {

      const operations = await getLookupSelectFieldItems(ApiQuery.GetOperations, LookupSelectFieldItemLabel.None);

      if (aborted)
        return;

      const fields: UserFormFields = {
        firstName: "",
        lastName: "",
        emailAddress: "",
        role: Role.Admin,
        id: 0,
        isActive: true,
        phoneNumber: null,
        mfaMethod: MultiFactorAuthenticationMethod.None,
        extraPermissions: []
      }

      setFormData({
        mode: UIFormMode.Create,
        datasources: {
          operations
        },
        fields
      })

    }

    loadPageData();

    return () => {
      aborted = true;
    };
  });


  const onSave = async (mode: UIFormMode, fields: UserFormFields) => {
    const { id, isActive, mfaMethod, ...createFields } = fields;
    const command: CreateUserCommand = {
      ...createFields,
      mfaMethod: mfaMethod === MultiFactorAuthenticationMethod.None ? null : mfaMethod
    };

    var result = await Api.executeCommand<CreateUserCommand, CreateUserCommandResult>(
      ApiCommand.CreateUser,
      command);

    return result.id;
  };

  const onCompleted = async (id: number) => {
    navigate(paths.admin.users.list)
  }

  return (
    <UISubPage
      title="Admin > Users > Create"
      backTitle="Users"
      backHref={paths.admin.users.list}>
      {!formData ? (

        <UIProgress />

      ) : (
        <UserForm
          onSave={onSave}
          onCompleted={onCompleted}
          data={formData} />
      )}
    </UISubPage>
  );
};

export default UserCreatePage;
