import { Box, Container, Stack, Typography } from '@mui/material';
import { Seo } from 'src/shared/components/seo';

import { useSettings } from 'src/shared/hooks/use-settings';


const DashboardPage = () => {
  const settings = useSettings();

  

  return (
    <>
      <Seo title="Dashboard" />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 2
        }}
      >
        <Container maxWidth={settings.stretch ? false : 'xl'}>
          <Stack
            spacing={{
              xs: 3,
              lg: 4
            }}
          >
            <Stack
              direction="row"
              justifyContent="space-between"
              spacing={4}
            >
              <div>
                <Typography variant="h4">
                  Dashboard
                </Typography>
              </div>
              <div>
                <Stack
                  direction="row"
                  spacing={4}
                >
                  {/* <Button
                    startIcon={(
                      <SvgIcon>
                        <PlusIcon />
                      </SvgIcon>
                    )}
                    variant="contained"
                  >
                    Action
                  </Button> */}
                </Stack>
              </div>
            </Stack>
            <Box
              sx={{
                borderColor: 'neutral.300',
                borderStyle: 'dashed',
                borderWidth: 1,
                height: 300,
                p: '4px'
              }}
            />
          </Stack>
        </Container>
      </Box>
    </>
  );
};

export default DashboardPage;
