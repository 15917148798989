import { useCallback, useEffect, useState, type FC } from 'react';
import type { DropzoneOptions, FileWithPath } from 'react-dropzone';
import { useDropzone } from 'react-dropzone';
import Upload01Icon from '@untitled-ui/icons-react/build/esm/Upload01';
import XIcon from '@untitled-ui/icons-react/build/esm/X';
import {
  Avatar,
  Box,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  SvgIcon,
  Tooltip,
  Typography
} from '@mui/material';
import { FileIcon } from './file-icon';
import { bytesToSize } from '../utils/bytes-to-size';


export type File = FileWithPath;

interface FileDropzoneProps extends DropzoneOptions {
  caption?: string;
  onUpload: (files: File[]) => void;
}

export const FileDropzone: FC<FileDropzoneProps> = (props) => {
  const { caption, onUpload, disabled, ...other } = props;
  const [files, setFiles] = useState<File[]>([]);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    ...other, 
    disabled:disabled ? true : !other.maxFiles? false : files.length >= other.maxFiles,
    onDrop: (newFiles: File[]): void => {
      setFiles((prevFiles) => {
          return [...prevFiles, ...newFiles];
      });
  }});
  const hasAnyFiles = files.length > 0;

  useEffect(() => {
    setFiles([]);
  },
    []
  );

  const handleRemove = useCallback(
    (file: File): void => {
      setFiles((prevFiles) => {
        return prevFiles.filter((_file) => _file.path !== file.path);
      });
    },
    []
  );

  const handleRemoveAll = useCallback(
    (): void => {
      setFiles([]);
    },
    []
  );

  return (
    <div>

      {(!hasAnyFiles || (hasAnyFiles && other.maxFiles && other.maxFiles > 1)) && (
      <Box
        sx={{
          alignItems: 'center',
          border: 1,
          borderRadius: 1,
          borderStyle: 'dashed',
          borderColor: 'divider',
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'center',
          outline: 'none',
          p: 6,
          mt:2,
          ...(
            isDragActive && {
              backgroundColor: 'action.active',
              opacity: 0.5
            }
          ),
          '&:hover': {
            backgroundColor: 'action.hover',
            cursor: 'pointer',
            opacity: 0.5
          }
        }}
        {...getRootProps()}
      >
        <input {...getInputProps()} />
        <Stack
          alignItems="center"
          direction="row"
          spacing={2}
        >
          <Avatar
            sx={{
              height: 64,
              width: 64
            }}
          >
            <SvgIcon>
              <Upload01Icon />
            </SvgIcon>
          </Avatar>
          <Stack spacing={1}>
            <Typography
              sx={{
                '& span': {
                  textDecoration: 'underline'
                }
              }}
              variant="h6"
            >
              <span>Click to select a file</span> or drag and drop
            </Typography>
            {caption && (
              <Typography
                color="text.secondary"
                variant="body2"
              >
                {caption}
              </Typography>
            )}
          </Stack>
        </Stack>
      </Box>
      )}
        <Box sx={{ mt: 2 }}>
        {hasAnyFiles && (
          <List>
            {files.map((file) => {
              const extension = file.name.split('.').pop();

              return (
                <ListItem
                  key={file.path}
                  sx={{
                    border: 1,
                    borderColor: 'divider',
                    borderRadius: 1,
                    '& + &': {
                      mt: 1
                    }
                  }}
                >
                  <ListItemIcon>
                    <FileIcon extension={extension} />
                  </ListItemIcon>
                  <ListItemText
                    primary={file.name}
                    primaryTypographyProps={{ variant: 'subtitle2' }}
                    secondary={bytesToSize(file.size)}
                  />
                  <Tooltip title="Remove">
                    <IconButton
                      edge="end"
                      onClick={() => handleRemove(file)}
                      disabled={disabled}
                    >
                      <SvgIcon>
                        <XIcon />
                      </SvgIcon>
                    </IconButton>
                  </Tooltip>
                </ListItem>
              );
            })}
          </List>
                )}
          <Stack
            alignItems="center"
            direction="row"
            justifyContent="flex-end"
            spacing={2}
            sx={{ mt: 2 }}
          >
            {(!other.maxFiles || other.maxFiles > 1) && (
            <Button
              color="inherit"
              onClick={handleRemoveAll}
              size="small"
              type="button"
              disabled={disabled || !hasAnyFiles}
            >
              Remove All
            </Button>
           )}
            <Button
              onClick={() => onUpload(files)}
              size="small"
              type="button"
              variant="contained"
              disabled={disabled || !hasAnyFiles}
            >
              Upload
            </Button>
          </Stack>
        </Box>

    </div>
  );
};

