import { GridColDef } from '@mui/x-data-grid';
import { ListPageLayout } from 'src/shared/layouts/page/list-page-layout';
import { UIDataGrid } from 'src/shared/components/ui/ui-data-grid';
import { useState } from 'react';
import { useMount } from 'src/shared/hooks/use-mount';
import { GetVesselsQuery, GetVesselsQueryResult } from 'src/api/queries/vessel/get-vessels';
import { Api } from 'src/api/api';
import { ApiQuery } from 'src/api/api-query';
import UIMessage from 'src/shared/components/ui/ui-message';
import { Chip, Skeleton } from '@mui/material';


const loadingRowNumber = 10;
const loadingRows: any[] = ((rows: number) => {
	return [...Array(rows)].map((row, index) => {
		return {
			id: Number.MAX_SAFE_INTEGER - index,
			name: null,
			isActive: null,
			operationName: null
		}
	});
})(loadingRowNumber);

const columns: GridColDef[] = [
	{
		field: 'id',
		headerName: 'ID',
		flex: 0.1,
		align: 'left',
		headerAlign: 'left',
		filterable: false,
		renderCell: (params) => params.value >= Number.MAX_SAFE_INTEGER - loadingRowNumber ? <Skeleton animation="wave" variant="text" /> : params.value,
	},
	{
		field: 'name',
		headerName: 'Name',
		flex: 0.20,
		align: 'left',
		headerAlign: 'left',
		filterable: false,
		renderCell: (params) => params.value == null ? <Skeleton animation="wave" variant="text" /> : params.value,
	},
	{
		field: 'isActive',
		headerName: 'Status',
		flex: 0.20,
		align: 'left',
		headerAlign: 'left',
		filterable: false,
		renderCell: (params) => params.value == null? <Skeleton animation="wave" variant="text" /> : params.value ?  <Chip size="small" label="Active" color="success" variant="outlined"/> :  <Chip size="small" label="Inactive" color="warning" variant="outlined" />,
	},
	{
		field: 'operationName',
		headerName: 'Operation',
		flex: 0.20,
		align: 'left',
		headerAlign: 'left',
		filterable: false,
		renderCell: (params) => params.value == null ? <Skeleton animation="wave" variant="text" /> : params.value,
	},
];

const VesselListPage = () => {

	const [rows, setRows] = useState<any[]>(loadingRows);
	const [loading, setLoading] = useState<boolean>(true);
	const [errors, setErrors] = useState<string[]>([]);

	useMount(() => {

		const loadData = async () => {
			try {
				const result = await Api.executeQuery<GetVesselsQuery, GetVesselsQueryResult>(ApiQuery.GetVessels, {});
				setRows(result.rows);
				setLoading(false);
			} catch (err) {
				setErrors(err.response.data.errors || [err.response.statusText])
				setLoading(false);
			}
		}

		loadData();
	});

	return (
		<ListPageLayout
			title="Vessels"
			addButtonPath={undefined}>
			<UIMessage
				type="error"
				messages={errors}
				onClose={() => setErrors([])} />

			<UIDataGrid
				loading={loading}
				hideFooterPagination={loading}
				rows={rows}
				columns={columns}
			/>

		</ListPageLayout>
	);
};

export default VesselListPage;
