
import type { ChangeEvent, MouseEvent } from 'react';
import { useCallback, useEffect, useState } from 'react';
import PlusIcon from '@untitled-ui/icons-react/build/esm/Plus';
import { Box, Button, Card, Container, Stack, SvgIcon, Typography } from '@mui/material';
import { Seo } from 'src/shared/components/seo';
import { useIsMounted } from 'src/shared/hooks/use-is-mounted';
// import { UserListSearch } from 'src/sections/admin/users/user-list-search';
import { UserListTable } from 'src/pages/admin/user/components/user-list-table';

import { UserRow } from 'src/models/user';
import { Api } from 'src/api/api';
import { ApiQuery } from "src/api/api-query";
import { GetUsersQuery, GetUsersQueryResult } from '../../../api/queries/user/get-users';
import { RouterLink } from 'src/shared/components/router-link';
import { paths } from 'src/routes/paths';
import { useNavigate } from 'react-router';
import { Permission } from 'src/models/permission';
import { useAuth } from 'src/shared/hooks/use-auth';
import { useMount } from 'src/shared/hooks/use-mount';

interface Filters {
	query?: string;
	hasAcceptedMarketing?: boolean;
	isProspect?: boolean;
	isReturning?: boolean;
}

interface UsersSearchState {
	filters: Filters;
	page: number;
	rowsPerPage: number;
	sortBy: string;
	sortDir: 'asc' | 'desc';
}

const useUsersSearch = () => {
	const [state, setState] = useState<UsersSearchState>({
		filters: {
			query: undefined,
			hasAcceptedMarketing: undefined,
			isProspect: undefined,
			isReturning: undefined
		},
		page: 0,
		rowsPerPage: 50,
		sortBy: 'updatedAt',
		sortDir: 'desc'
	});

	const handleFiltersChange = useCallback(
		(filters: Filters): void => {
			setState((prevState) => ({
				...prevState,
				filters
			}));
		},
		[]
	);

	const handleSortChange = useCallback(
		(sort: { sortBy: string; sortDir: 'asc' | 'desc'; }): void => {
			setState((prevState) => ({
				...prevState,
				sortBy: sort.sortBy,
				sortDir: sort.sortDir
			}));
		},
		[]
	);

	const handlePageChange = useCallback(
		(event: MouseEvent<HTMLButtonElement> | null, page: number): void => {
			setState((prevState) => ({
				...prevState,
				page
			}));
		},
		[]
	);

	const handleRowsPerPageChange = useCallback(
		(event: ChangeEvent<HTMLInputElement>): void => {
			setState((prevState) => ({
				...prevState,
				rowsPerPage: parseInt(event.target.value, 10)
			}));
		},
		[]
	);

	return {
		handleFiltersChange,
		handleSortChange,
		handlePageChange,
		handleRowsPerPageChange,
		state
	};
};

interface UsersStoreState {
	users: UserRow[];
	usersCount: number;
}

const useUsersStore = (searchState: UsersSearchState) => {
	const isMounted = useIsMounted();
	const [state, setState] = useState<UsersStoreState>({
		users: [],
		usersCount: 0
	});

	const handleUsersGet = useCallback(
		async () => {
			try {

				const result = await Api.executeQuery<GetUsersQuery, GetUsersQueryResult>
					(ApiQuery.GetUsers, {});

				if (isMounted()) {
					setState({
						users: result.rows,
						usersCount: result.rows.length
					});
				}
			} catch (err) {
				console.error(err);
			}
		},
		[isMounted]
	);

	useEffect(
		() => {
			handleUsersGet();
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[searchState]
	);

	return {
		...state
	};
};



const UserListPage = () => {
	const usersSearch = useUsersSearch();
	const usersStore = useUsersStore(usersSearch.state);
	const navigate = useNavigate();
	const auth = useAuth();
  
	useMount(()=>{
	  if (!auth.authState.principal?.hasPermission(Permission.Administration)) {
		navigate(paths.error403);
		return;
	  }
	})
	
	return (
		<>
			<Seo title="Admin > Users" />
			<Box
				component="main"
				sx={{
					flexGrow: 1,
					py: 3
				}}
			>
				<Container maxWidth="xl">
					<Stack spacing={4}>
						<Stack
							direction="row"
							justifyContent="space-between"
							spacing={4}
						>
							<Stack spacing={1}>
								<Typography variant="h4">
									Users
								</Typography>
							</Stack>
							<Stack
								alignItems="center"
								direction="row"
								spacing={3}
							>
								<Button
									startIcon={(
										<SvgIcon>
											<PlusIcon />
										</SvgIcon>
									)}
									variant="contained"
									component={RouterLink}
									href={paths.admin.users.create}
								>
									Add
								</Button>
							</Stack>
						</Stack>
						<Card>
							{/*<UserListSearch*/}
							{/*  onFiltersChange={usersSearch.handleFiltersChange}*/}
							{/*  onSortChange={usersSearch.handleSortChange}*/}
							{/*  sortBy={usersSearch.state.sortBy}*/}
							{/*  sortDir={usersSearch.state.sortDir}*/}
							{/*/>*/}
							<UserListTable
								count={usersStore.usersCount}
								items={usersStore.users}
								onPageChange={usersSearch.handlePageChange}
								onRowsPerPageChange={usersSearch.handleRowsPerPageChange}
								page={usersSearch.state.page}
								rowsPerPage={usersSearch.state.rowsPerPage}
							/>
						</Card>
					</Stack>
				</Container>
			</Box>
		</>
	);
};

export default UserListPage;
