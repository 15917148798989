
import { ApiQuery } from 'src/api/api-query';
import { Permission } from 'src/models/permission';
import LookupListPage from 'src/shared/pages/lookup-list-page';

const MintListPage = () => {
	return (
		<LookupListPage 
			permissions={[Permission.DataManagementRead]} 
			title="Mints"
			pageSize={25}
			apiQuery={ApiQuery.GetMints}
			addButtonPath={undefined}
		/>
	)
}

export default MintListPage;
