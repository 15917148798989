import { CircularProgress } from "@mui/material"
import { Box } from "@mui/system";
import { FC } from "react"

export const UIProgress: FC = () => {
    return(
    <Box sx={{width:'100%', height:'500px'}}>
         <CircularProgress sx={{position:'relative', top:'50%', left:'50%'}}/>
    </Box>
    );
}