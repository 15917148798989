import { FormControl, Unstable_Grid2 as Grid, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { FormikType, UIFieldProps } from "./ui-field";
import React, { ReactNode } from "react";

export enum UISelectFieldType {
    Single,
    Multiple
}

export interface UISelectFieldItem<T extends number | string> {
    label: string;
    value: T;
}

interface UISelectFieldProps<T extends number | string> extends UIFieldProps {
    name: string;
    label: string;
    required: boolean;
    items: UISelectFieldItem<T>[]
    type?: UISelectFieldType;
    onChange?: (name:string, value: T | T[], formik: FormikType) => Promise<void>;
}

const UISelectField = <T extends number | string,>({ name, label, required, formik, items, type = UISelectFieldType.Single, onChange }: UISelectFieldProps<T>) => {
   
    const handleChange = (event: SelectChangeEvent<any>, child: ReactNode) => {
        if(!formik) return;

        formik.handleChange(event, child);
        onChange?.(name,event.target.value, formik);
    }

    return !formik ? (<></>) : (
        <Grid
            xs={12}
            md={12}
        >
                <FormControl  key={name + "control"} fullWidth required={required}>
                <InputLabel sx={{ pb: 2 }} id={`${name}label`}>{label}</InputLabel>

                <Select
                    labelId={`${name}label`}
                    key={name}
                    label={label}
                    name={name}
                    required={required}
                    multiple={type === UISelectFieldType.Multiple}
                    fullWidth
                    error={!!(formik.touched[name] && formik.errors[name])}
                    onBlur={formik.handleBlur}
                    onChange={handleChange}
                    //value={formik.values[name] != null ? formik.values[name] : -1}
                    value={formik.values[name] != null ? formik.values[name] === 0 ? '' : formik.values[name] : -1}
                >
                    {items.map((item) => (
                        <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>
                    ))}
                </Select>
            </FormControl>


            {/* <FormControl key={name + "control"} fullWidth>
                <InputLabel sx={{ pb: 2 }} id={`${name}label`}>&nbsp;{label}</InputLabel>
                <NativeSelect
                  key={name}
                  name={name}
                  required={required}
                  fullWidth
                  error={!!(formik.touched[name] && formik.errors[name])}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values[name] != null ? formik.values[name] : -1}
                >
                       {items.map((item) => (
                        <option key={item.value} value={item.value}>{item.label}</option>
                        ))}

                </NativeSelect>
            </FormControl> */}
            {/* <Autocomplete
                disablePortal
                id={name}
                options={items}
                fullWidth
                value={(formik.values[name] ? formik.values[name] + "" : "0")}
                isOptionEqualToValue={(option, value) => option === value}
                getOptionLabel={(option) => option ?  (items.find(o => o.id == ((option as unknown) as number))?.label || "") : ""}
                onChange={(event: any, newValue: any) => {
                  formik.setFieldValue(name, newValue?.id, false)
                }}
                renderInput={(params) =>  {return (
                    <TextField {...params}
                        label={label}
                        key={`${name}label`}
                    />
                )}}/> */}

        </Grid>
    );
};

export default UISelectField;
