import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import React from "react";

interface UIConfirmDialogRecord {
    id: number;
    name: string;
}
interface UIConfirmDialogProps {
    title: string;
    record?: UIConfirmDialogRecord;
    onConfirm: (id: number) => Promise<void>;
    onCancel: () => void;
}

const UIConfirmDialog = (props: UIConfirmDialogProps) => {
  const { title, record, onConfirm, onCancel } = props;
  return (
    <Dialog
      open={!!record}
      onClose={() => onCancel()}
      aria-labelledby="confirm-dialog"
    >
      <DialogTitle id="confirm-dialog">{title}</DialogTitle>
      <DialogContent>Are you sure you want to delete <strong>{record?.name}</strong>?</DialogContent>
      <DialogActions>
        <Button

          onClick={() => onCancel()}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={async() =>  await onConfirm(record?.id || 0)}>
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UIConfirmDialog;