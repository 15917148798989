import { Button, Chip, Stack, Typography } from "@mui/material";
import { useFormik } from "formik";
import React, { ReactElement, useEffect, useState } from "react";
import { FC, ReactNode } from "react";
import toast from "react-hot-toast";
import { RouterLink } from "src/shared/components/router-link";
import { FormikType, UIFieldProps } from "./ui-field";
import UIMessage from "./ui-message";
import { UIFormSection } from "./ui-form-section";

export enum UIFormMode {
    Create,
    Update
}

interface UIFormProps {
    mode: UIFormMode;
    useCustomSections?: boolean;
    entity: string;
    children: ReactNode;
    cancelHref: string;
    fields?: any;
    onBeforeSave: (mode: UIFormMode, values: any) => Promise<any>;
    onSave: (mode: UIFormMode, values: any) => Promise<any>;
    onCompleted: (id: any) => Promise<void>;
    onLoad?:(formik: FormikType) => Promise<void>;
}
const UIForm: FC<UIFormProps> = (props) => {
    const { entity, mode, fields, children, cancelHref, onSave, onBeforeSave, onCompleted, onLoad, useCustomSections = false } = props;
    const [errors, setErrors] = useState<string[]>();

    const formik = useFormik({
        initialValues: fields || {},
        validateOnChange:false,
        onSubmit: async (values, helpers): Promise<void> => {
            let formValues = { ...values };
            //Ensure form values set to -1 are set to null.
            Object.keys(formValues).forEach((key) => {
                if (formValues[key] === -1) {
                    formValues[key] = null;
                }
            });
            formValues = await onBeforeSave(mode, formValues);
            try {
                var id = await onSave(mode, formValues);
                helpers.setStatus({ success: true });
                helpers.setSubmitting(false);
                toast.success(mode === UIFormMode.Create ? `${entity} created.` : `${entity} updated.`);
                await onCompleted(id)
            } catch (err) {
                helpers.setStatus({ success: false });
                helpers.setSubmitting(false);
                toast.error(mode === UIFormMode.Create ? `Error creating ${entity}` : `Error updating ${entity}`);
                if (err.name === "AxiosError") {
                    setErrors(err?.response?.data?.errors);
                } else {
                    setErrors(["An unknown error occurred. Please try again."]);
                    console.error(err);
                }
            }
        }
    });

    const title = mode === UIFormMode.Create ? `Create ${entity}` : `Edit ${entity}`;

    useEffect(()=>{
        onLoad?.(formik);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    return (
        <>
            <Stack spacing={3}>
                <Stack spacing={1}>
                    <Typography variant="h4">
                        {title}
                    </Typography>
                    <UIMessage
                    type="error"
                    messages={errors}
                    onClose={() => setErrors([])} />
                </Stack>
                <form
                    onSubmit={formik.handleSubmit}
                >
                    <Stack spacing={4}>

                        {mode === UIFormMode.Update && (
                            <UIFormSection title="ID" formik={formik}>
                                <Stack
                                    alignItems="center"
                                    direction="row"
                                    spacing={1}
                                >
                                    <Chip
                                        label={fields?.id}
                                        size="medium"
                                    />
                                </Stack>
                            </UIFormSection>
                        )}

                        {!useCustomSections ? (
                            <UIFormSection title={"Fields"} children={children} formik={formik} />
                        ) : (
                            <>
                                {React.Children.map(children, child => {
                                    return React.cloneElement(child as ReactElement<UIFieldProps>, { formik })
                                })}
                            </>
                        )}

                        <Stack
                            direction={{
                                xs: 'column',
                                sm: 'row'
                            }}
                            flexWrap="wrap"
                            spacing={3}
                            sx={{ p: 3 }}
                        >
                            <Button
                                disabled={formik.isSubmitting}
                                type="submit"
                                variant="contained"
                            >
                                Save
                            </Button>
                            <Button
                                color="inherit"
                                component={RouterLink}
                                disabled={formik.isSubmitting}
                                href={cancelHref}
                            >
                                Cancel
                            </Button>
                        </Stack>

                    </Stack>
                </form>
            </Stack>
        </>

    );
};

export default UIForm;