import { Chip } from "@mui/material"
import { Stack } from "@mui/system"
import React from "react"
import { ArtifactModel } from "src/models/artifact"
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import PlaceIcon from '@mui/icons-material/Place';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import ArticleIcon from '@mui/icons-material/Article';

const ArtifactTags: React.FC<{artifact?: ArtifactModel}> = ({artifact}) => {
    return  (
    <Stack
    alignItems="center"
    direction="row"
    spacing={1}
  >

    <Stack spacing={1}>
      <Stack
        alignItems="center"
        direction="row"
        spacing={1}
      >
        <Chip icon={<LocalOfferIcon />} label={artifact?.tag || "Untagged"} variant="outlined" size="medium" />
        <Chip icon={<PlaceIcon />} label={artifact?.wreckSiteName} variant="outlined" size="medium" />
        <Chip icon={<ArticleIcon />} label={artifact?.artifactDescriptionName} variant="outlined" size="medium" />
        <Chip icon={<CalendarMonthIcon />} label={artifact?.year} variant="outlined" size="medium" />
        {artifact?.highTicket && (
          <Chip icon={<MonetizationOnIcon />} label={"High Ticket"} variant="outlined" size="medium" />
        )}
      </Stack>
    </Stack>
  </Stack>
    )
};

export default ArtifactTags;