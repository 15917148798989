import { Card, CardContent, Unstable_Grid2 as Grid, Stack, Typography } from "@mui/material";
import React, { ReactElement, ReactNode } from "react";
import { FC } from "react";
import { FormikType, UIFieldProps } from "./ui-field";

export interface UIFormSectionProps {
    title: string;
    subtitle?: string;
    children: ReactNode;
    formik?: FormikType;
    hidden?: boolean;
}

export const UIFormSection: FC<UIFormSectionProps> = (props) => {
    const { title, subtitle, children, formik, hidden = false } = props;
    return (<>
        <Card hidden={hidden}>
            <CardContent>
                <Grid
                    container
                    spacing={3}
                >
                    <Grid
                        xs={12}
                        md={2}
                    >
                        <Typography variant="h6">
                            {title}
                        </Typography>
                        {subtitle && (
                            <Typography
                                color="text.secondary"
                                variant="body2"
                            >
                                {subtitle}
                            </Typography>
                        )}

                    </Grid>
                    <Grid
                        xs={12}
                        md={10}
                    >
                        <Stack spacing={3}>
                            {React.Children.map(children, child => {
                                return React.cloneElement(child as ReactElement<UIFieldProps>, { formik });
                            })}
                        </Stack>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    </>);
};
