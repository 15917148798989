import { paths } from 'src/routes/paths';
import { Api } from 'src/api/api';
import { ApiCommand, CreateCommandResult, UpdateCommandResult } from "src/api/api-command";
import UISubPage from 'src/shared/components/ui/ui-sub-page';
import { useNavigate, useParams } from 'react-router-dom';
import { UIFormMode } from 'src/shared/components/ui/ui-form';
import ArtifactForm, { ArtifactFormData, ArtifactFormFields } from './components/artifact-form';
import { useState } from 'react';
import { ApiQuery } from 'src/api/api-query';
import { GetLookupRowsQuery, GetLookupRowsQueryResult } from 'src/api/queries/lookup/get-lookup-rows';
import toast from 'react-hot-toast';
import { UIProgress } from 'src/shared/components/ui/ui-progress';
import { UISelectFieldItem } from 'src/shared/components/ui/ui-select-field';
import { CreateArtifactCommand, UpdateArtifactCommand } from 'src/api/commands/artifact';
import { GetArtifactQuery, GetArtifactQueryResult } from 'src/api/queries/artifact/get-artifact';
import { useAuth } from 'src/shared/hooks/use-auth';
import { Permission } from 'src/models/permission';
import { useMount } from 'src/shared/hooks/use-mount';


const ArtifactManagePage = () => {
  const [pageTitle, setPageTitle] = useState<string>("");
  const [formData, setFormData] = useState<ArtifactFormData>();
  const [entityBackPath, setEntityBackPath] = useState<string>(paths.artifacts.list);
  const [entityBackTitle, setEntityBackTitle] = useState<string>("Artifacts");
  const { id } = useParams();
  const navigate = useNavigate();
  const auth = useAuth();
  
  const onSave = async (mode: UIFormMode, fields: ArtifactFormFields): Promise<number> => {

    if (mode === UIFormMode.Update) {
      const command: UpdateArtifactCommand = {
        ...fields,
        id: fields.id!,
      };

      await Api.executeCommand<UpdateArtifactCommand, UpdateCommandResult>(
        ApiCommand.UpdateArtifact,
        command);

      return command.id;

    } else {
      const { id, isActive, ...createFields } = fields;
      const command: CreateArtifactCommand = {
        ...createFields,
      };

      const result = await Api.executeCommand<CreateArtifactCommand, CreateCommandResult>(
        ApiCommand.CreateArtifact,
        command);

      return result.id;
    }

  };

  const onCompleted = async (id: number) => {
    navigate(paths.artifacts.list +"#" + id)
  }

  useMount(() => {
    let aborted = false;
    const idNumber = +(id || 0); //Router Param
    const mode: UIFormMode = idNumber ? UIFormMode.Update : UIFormMode.Create;
    const permission = (mode === UIFormMode.Create) ? Permission.ArtifactsCreate : Permission.ArtifactsEdit;   
    if (!auth.authState.principal?.hasPermission(permission)) {
      navigate(paths.error403);
      return;
    }
    
    if(mode === UIFormMode.Update) {
      setEntityBackPath(paths.artifacts.list +"#" + idNumber);
      setEntityBackTitle("Artifact Details");
    }
    async function getLookupSelectFieldItems(apiQuery: ApiQuery): Promise<UISelectFieldItem<number>[]> {
      const result = await Api.executeQuery<GetLookupRowsQuery, GetLookupRowsQueryResult>(apiQuery, {});
      const rows = result?.rows.map<UISelectFieldItem<number>>(r => ({ label: r.name, value: r.id })) || [];
      rows.unshift({value: -1, label: "< None >"})
      return rows;
    }

    async function onPageLoad() {

      try {
        let record: ArtifactFormFields;
        setPageTitle(`${entityBackTitle} > ${UIFormMode[mode]}`);
        if (mode === UIFormMode.Update) {
          const result = await Api.executeQuery<GetArtifactQuery, GetArtifactQueryResult>(
            ApiQuery.GetArtifact, { id: idNumber }
          );
          record = {
            ...result.record,
            divisonEligibleNumber: result.record.divisible ? 1 : 0, //UI is select
            highTicketNumber: result.record.highTicket ? 1 : 0, //UI is select
          }
        } else {
          record = {
            divisonEligibleNumber: 0,
            highTicketNumber: 0
          }
        }

        const operations = await getLookupSelectFieldItems(ApiQuery.GetOperations);
        const vessels = await getLookupSelectFieldItems(ApiQuery.GetVessels);
        const wreckSites = await getLookupSelectFieldItems(ApiQuery.GetWreckSites);
        const artifactDescriptions = await getLookupSelectFieldItems(ApiQuery.GetArtifactDescriptions);
        const curatorDispositions = await getLookupSelectFieldItems(ApiQuery.GetCuratorDispositions);
        const conservationResults = await getLookupSelectFieldItems(ApiQuery.GetConservationResults);
        const owners = await getLookupSelectFieldItems(ApiQuery.GetOwners);
        const artifactTypes = await getLookupSelectFieldItems(ApiQuery.GetArtifactTypes);
        const conservators = await getLookupSelectFieldItems(ApiQuery.GetConservators);
        const artifactStatuses = await getLookupSelectFieldItems(ApiQuery.GetArtifactStatuses);
        const concretionRemovals = await getLookupSelectFieldItems(ApiQuery.GetConcretionRemovalMethods);
        const protocols = await getLookupSelectFieldItems(ApiQuery.GetProtocols);
        const chlorideRemovals = await getLookupSelectFieldItems(ApiQuery.GetChlorideRemovals);
        const chlorideReleaseTests = await getLookupSelectFieldItems(ApiQuery.GetChlorideReleaseTests);
        const chemicalStabilizations = await getLookupSelectFieldItems(ApiQuery.GetChemicalStabilizations);
        const dispositions = await getLookupSelectFieldItems(ApiQuery.GetDispositions);
        const pearlGrades = await getLookupSelectFieldItems(ApiQuery.GetPearlGrades);
        const artifactClasses = await getLookupSelectFieldItems(ApiQuery.GetArtifactClasses);
        const denominations = await getLookupSelectFieldItems(ApiQuery.GetDenominations);
        const mints = await getLookupSelectFieldItems(ApiQuery.GetMints);
        const reigns = await getLookupSelectFieldItems(ApiQuery.GetReigns);
        const grades = await getLookupSelectFieldItems(ApiQuery.GetGrades);
        const assayers = await getLookupSelectFieldItems(ApiQuery.GetAssayers);

        const divisionEligibilityOptions = [
          {label: "Eligible", value: 1,},
          {label: "Not Eligible", value: 0}
        ]
        const highTicketOptions = [
          {label: "Yes", value: 1,},
          {label: "No", value: 0}
        ]

        if (aborted)
          return;

        setFormData({
          mode,
          fields: { ...record},
          datasources: {
            artifactTypes,
            conservators,
            artifactStatuses,
            concretionRemovals,
            protocols,
            chlorideRemovals,
            chlorideReleaseTests,
            chemicalStabilizations,
            dispositions,
            divisionEligibilityOptions,
            operations,
            vessels,
            wreckSites,
            artifactDescriptions,
            curatorDispositions,
            conservationResults,
            owners,
            highTicketOptions,
            pearlGrades,
            artifactClasses,
            denominations,
            mints,
            reigns,
            grades,
            assayers
          }
        });


      } catch (err) {
        console.log(err);
        toast.error(`${entityBackTitle} could not be loaded.`);
        navigate(paths.artifacts.list)
      }

    }

    onPageLoad();

    return () => {
      aborted = true;
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  });



  return (
    <UISubPage
      title={pageTitle}
      backTitle={entityBackTitle}
      backHref={entityBackPath}>
      {!formData ? (
        <UIProgress/>
      ) : (
        <ArtifactForm
          entityName="Artifact"
          cancelHref={entityBackPath}
          onSave={onSave}
          onCompleted={onCompleted}
          data={formData} />
      )}
    </UISubPage>
  );
};

export default ArtifactManagePage;
