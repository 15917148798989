
import type { ChangeEvent, MouseEvent } from 'react';
import { useCallback, useEffect, useState } from 'react';
import PlusIcon from '@untitled-ui/icons-react/build/esm/Plus';
import { Box, Button, Card, Container, Stack, SvgIcon, Typography } from '@mui/material';
import { Seo } from 'src/shared/components/seo';
import { useIsMounted } from 'src/shared/hooks/use-is-mounted';
import { IpAddressTable } from 'src/pages/admin/ip-address/components/ip-address-table';

import { IpAddressRow } from 'src/models/ip-address';
import { Api } from 'src/api/api';
import { ApiQuery } from "src/api/api-query";
import { ApiCommand } from "src/api/api-command";
import { GetIpAddressesQuery, GetIpAddressesQueryResult } from '../../../api/queries/ip-address/get-ip-addresses';
import { paths } from 'src/routes/paths';
import { RouterLink } from 'src/shared/components/router-link';
import { DeleteIpAddressCommand, DeleteIpAddressCommandResult } from 'src/api/commands/ip-address';
import toast from 'react-hot-toast';

interface IpAddressesListState {
	page: number;
	rowsPerPage: number;
	sortBy: string;
	sortDir: 'asc' | 'desc';
}

const useIpAddressesSearch = () => {
	const [state, setState] = useState<IpAddressesListState>({
		page: 0,
		rowsPerPage: 50,
		sortBy: 'description',
		sortDir: 'asc'
	});

	const handleSortChange = useCallback(
		(sort: { sortBy: string; sortDir: 'asc' | 'desc'; }): void => {
			setState((prevState) => ({
				...prevState,
				sortBy: sort.sortBy,
				sortDir: sort.sortDir
			}));
		},
		[]
	);

	const handlePageChange = useCallback(
		(event: MouseEvent<HTMLButtonElement> | null, page: number): void => {
			setState((prevState) => ({
				...prevState,
				page
			}));
		},
		[]
	);

	const handleRowsPerPageChange = useCallback(
		(event: ChangeEvent<HTMLInputElement>): void => {
			setState((prevState) => ({
				...prevState,
				rowsPerPage: parseInt(event.target.value, 10)
			}));
		},
		[]
	);

	return {
		handleSortChange,
		handlePageChange,
		handleRowsPerPageChange,
		state
	};
};

interface IpAddressesStoreState {
	IpAddresses: IpAddressRow[];
	IpAddressesCount: number;
}

const useIpAddressesStore = (searchState: IpAddressesListState) => {
	const isMounted = useIsMounted();
	const [state, setState] = useState<IpAddressesStoreState>({
		IpAddresses: [],
		IpAddressesCount: 0
	});

	const handleIpAddressesGet = useCallback(
		async () => {
			try {

				const result = await Api.executeQuery<GetIpAddressesQuery, GetIpAddressesQueryResult>
					(ApiQuery.GetIpAddresses, {});

				if (isMounted()) {
					setState({
						IpAddresses: result.rows,
						IpAddressesCount: result.rows.length
					});
				}
			} catch (err) {
				console.error(err);
			}
		},
		[isMounted]
	);

	useEffect(
		() => {
			handleIpAddressesGet();
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[searchState]
	);

	return {
		refresh: () => handleIpAddressesGet(),
		...state
	};
};

const onDeleteIpAddress = async (id: number) => {
   
    const command: DeleteIpAddressCommand = {
      id
    };

    var result = await Api.executeCommand<DeleteIpAddressCommand, DeleteIpAddressCommandResult>(
      ApiCommand.DeleteIpAddress,
      command);

    return result.result;
  };

const IpAddressListPage = () => {
	const ipAddressesSearch = useIpAddressesSearch();
	const ipAddressesStore = useIpAddressesStore(ipAddressesSearch.state);
	const onDelete = useCallback(async(id:number) => {
		
		try {
			const status = await onDeleteIpAddress(id)
			if(status) {
				await ipAddressesStore.refresh();
				toast.success("IP Address Deleted")
			}else {
				await ipAddressesStore.refresh();
			}
		}catch(err) {
			toast.error("IP Address was not deleted")
		}
	
	}, [ipAddressesStore])

	return (
		<>
			<Seo title="Admin > Ip Addresses" />
			<Box
				component="main"
				sx={{
					flexGrow: 1,
					py: 3
				}}
			>
				<Container maxWidth="xl">
					<Stack spacing={4}>
						<Stack
							direction="row"
							justifyContent="space-between"
							spacing={4}
						>
							<Stack spacing={1}>
								<Typography variant="h4">
									Ip Addresses
								</Typography>
							</Stack>
							<Stack
								alignItems="center"
								direction="row"
								spacing={3}
							>
								<Button
									startIcon={(
										<SvgIcon>
											<PlusIcon />
										</SvgIcon>
									)}
									variant="contained"
									component={RouterLink}
									href={paths.admin.ipAddresses.create}
								>
									Add
								</Button>
							</Stack>
						</Stack>
						<Card>
							<IpAddressTable
								count={ipAddressesStore.IpAddressesCount}
								items={ipAddressesStore.IpAddresses}
								onPageChange={ipAddressesSearch.handlePageChange}
								onRowsPerPageChange={ipAddressesSearch.handleRowsPerPageChange}
								page={ipAddressesSearch.state.page}
								rowsPerPage={ipAddressesSearch.state.rowsPerPage}
								onDelete={onDelete}
							/>
						</Card>
					</Stack>
				</Container>
			</Box>
		</>
	);
};

export default IpAddressListPage;
