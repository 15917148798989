import { FC } from 'react';
import { paths } from 'src/routes/paths';
import UIForm, { UIFormMode } from 'src/shared/components/ui/ui-form';
import UITextField from 'src/shared/components/ui/ui-text-field';


export interface IpAddressFormData {
  id?: number;
  address: string;
  description: string;
}

interface IpAddressFormProps {
  mode: UIFormMode;
  data: IpAddressFormData;
  onSave: (mode: UIFormMode, data: IpAddressFormData) => Promise<number>;
  onCompleted: (id: string) => Promise<void>;
}

export const IpAddressForm: FC<IpAddressFormProps> = (props) => {
  const { mode, data, onSave, onCompleted } = props;
  const onBeforeSave = async (mode: UIFormMode, data: IpAddressFormData) => {
    return data;
  };
  return (
      <UIForm
        mode={mode}
        entity="IP Address"
        cancelHref={paths.admin.ipAddresses.list}
        onBeforeSave={onBeforeSave}
        onSave={onSave}
        onCompleted={onCompleted}
        fields={data}>

        <UITextField
          name="address"
          label="IP Address"
          required={false} />

        <UITextField
          name="description"
          label="Description"
          required={false} />

      </UIForm>
  );
};