/*
https://github.com/dsherret/ts-nameof/issues/121
Examples: 
interface SomeInterface {
  someProperty: number;
}

// with types
console.log(nameof<SomeInterface>("someProperty")); // "someProperty"

// with values
const myVar: SomeInterface = { someProperty: 5 };
console.log(nameof(myVar, "someProperty")); // "someProperty"
*/

export function nameof<TObject>(obj: TObject, key: keyof TObject): string;
export function nameof<TObject>(key: keyof TObject): string;
export function nameof(key1: any, key2?: any): any {
  return key2 ?? key1;
}