import { FormControl, TextField } from "@mui/material";
import { ChangeEvent, forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { FormFieldHandle } from "./form-field";

interface FormTextInputProps {
    label: string;
    name: string;
    initialValue: string;
    onChange: (key: string, value: string | null) => void;
    disabled?: boolean;
}

export interface FormTextInputHandle extends FormFieldHandle{
    getValue: () => string | null;
    setValue: (value: string | null) => void;
};


const FormTextInput = forwardRef<FormTextInputHandle, FormTextInputProps>(({ label, name, initialValue, onChange, disabled = false}, ref) => {
    const [fieldValue, setFieldValue] = useState<string>(initialValue);
    const [fieldDisabled, setFieldDisabled] = useState<boolean>(disabled);

    useImperativeHandle(ref, () => ({
        getValue() {
            return fieldValue;
        },
        setValue(value: string | null) {
            changeFieldValue(value);
        },
        resetValue() {
            changeFieldValue(initialValue);
        },
        isDisabled() {
            return fieldDisabled;
        },
        setDisabled(value: boolean) {
            setFieldDisabled(value);
        },
    }));

    const handleFieldChanged = (e: ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        changeFieldValue(value);
    }

    const changeFieldValue = (value: any) => {
        setFieldValue(value);
        onChange(name, value);
    }

    useEffect(()=>{
        setFieldDisabled(disabled);
    },[disabled])


    return (
        <FormControl>
            <TextField
                fullWidth
                name={name}
                value={fieldValue}
                onChange={handleFieldChanged}
                label={label}
                InputLabelProps={{ shrink: true }}
            />

        </FormControl>
    )
});

export default FormTextInput;