
import { GridColDef, GridColumnVisibilityModel } from '@mui/x-data-grid';
import { UIDataGrid } from 'src/shared/components/ui/ui-data-grid';
import { FC, useEffect, useRef, useState } from 'react';
import { useMount } from 'src/shared/hooks/use-mount';
import { Api } from 'src/api/api';
import { ApiQuery } from 'src/api/api-query';
import UIMessage from 'src/shared/components/ui/ui-message';
import { paths } from 'src/routes/paths';
import { nameof } from 'src/shared/utils/nameof';
import { textColumn, idColumn, dateColumn } from 'src/shared/components/grid-columns';
import { useAuth } from 'src/shared/hooks/use-auth';
import { useLocation, useNavigate } from 'react-router';
import { Permission } from 'src/models/permission';
import { ArtifactRow, ArtifactTagSearchMode } from 'src/models/artifact';
import { GetArtifactsQuery, GetArtifactsQueryResult } from 'src/api/queries/artifact/get-artifacts';
import { styled } from '@mui/material/styles';
import ArtifactSearchPanel, { ArtifactSearchPanelDatasources, ArtifactSearchPanelFields } from './components/artifact-search-panel';
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Stack, SvgIcon, Typography } from '@mui/material';
import PlusIcon from '@untitled-ui/icons-react/build/esm/Plus';
import MoveDownIcon from '@mui/icons-material/MoveDown';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { GetLookupRowsQuery, GetLookupRowsQueryResult } from 'src/api/queries/lookup/get-lookup-rows';
import { FormSelectItem } from 'src/shared/components/form/form-select';
import { splitCamelCase } from 'src/shared/utils/split-camel-case';
import { ArtifactDrawer } from './components/artifact-drawer';
import { useDialog } from 'src/shared/hooks/use-dialog';
import { RouterLink } from 'src/shared/components/router-link';
import { GetWreckSitesQuery, GetWreckSitesQueryResult } from 'src/api/queries/wreck-site/get-wreck-sites';
import { GetVesselsQuery, GetVesselsQueryResult } from 'src/api/queries/vessel/get-vessels';


const ArtifactGridContainer = styled(
    'div',
    { shouldForwardProp: (prop) => prop !== 'open' }
)<{ open?: boolean; }>(
    ({ theme, open }) => ({
        flexGrow: 1,
        overflow: 'auto',
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        zIndex: 1,
        // [theme.breakpoints.up('lg')]: {
        //   marginLeft: -380
        // },
        marginLeft: -380 + 24,
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        }),
        ...(open && {
            // [theme.breakpoints.up('lg')]: {
            //   marginLeft: 0
            // },
            marginLeft: 0,
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen
            })
        })
    })
);


const ArtifactTransferPage: FC = () => {
    const auth = useAuth();
    const navigate = useNavigate();
    const { hash } = useLocation();
    const addButtonAccessible = auth.authState.principal?.hasPermission(Permission.ArtifactsCreate) || false;
    const loadingSize = 10;
    const loadingRows: any[] = ((rows: number) => {
        return [...Array(rows)].map((row, index) => {
            return {
                id: Number.MAX_SAFE_INTEGER - index,
                name: null,
                isActive: null,
            }
        });
    })(loadingSize);


    const columns: GridColDef[] = [
        idColumn(loadingSize, nameof<ArtifactRow>("id")),
        textColumn(nameof<ArtifactRow>("tag"), "Tag"),
        textColumn(nameof<ArtifactRow>("artifactDescriptionName"), "Description"),
        textColumn(nameof<ArtifactRow>("wreckSiteName"), "Wreck Site"),
        dateColumn(nameof<ArtifactRow>("recoveryDate"), "Recovered"),
        textColumn(nameof<ArtifactRow>("points"), "Points"),
        textColumn(nameof<ArtifactRow>("quantity"), "Quantity"),
    ];
    const [rows, setRows] = useState<any[]>(loadingRows);
    const [loading, setLoading] = useState<boolean>(true);
    const [errors, setErrors] = useState<string[]>([]);
    const [columnVisibilityModel, setColumnVisibilityModel] = useState<GridColumnVisibilityModel>({
        // Default hidden columns
        [nameof<ArtifactRow>("id")]: false,
        [nameof<ArtifactRow>("tag")]: true,
        [nameof<ArtifactRow>("artifactDescriptionName")]: true,
        [nameof<ArtifactRow>("recoveryDate")]: true,
        [nameof<ArtifactRow>("points")]: true,
        [nameof<ArtifactRow>("quantity")]: true,

    });
    const rootRef = useRef<HTMLDivElement | null>(null);
    const [searchPanelOpen, setSearchPanelOpen] = useState<boolean>(true);
    const [selectedArtifactId, setSelectedArtifactId] = useState<number>();
    const [artifactSearchPanelDatasources, setArtifactSearchPanelDatasources] = useState<ArtifactSearchPanelDatasources>();

    const dialog = useDialog<number>();

    const handleArtifactSelect = (artifactId: string): void => {
        navigate('#' + artifactId);
    };

    const handleArtifactDrawerClose = (): void => {
        navigate("#");
    }


    const handleSearchPanelSubmit = async (fields: ArtifactSearchPanelFields) => {
        setRows(loadingRows);
        setLoading(true);
        const result = await Api.executeQuery<GetArtifactsQuery, GetArtifactsQueryResult>(
            ApiQuery.GetArtifacts, {
            ...fields
        });
        setRows(result.rows);

        setLoading(false);
    };

    const handleSearchPanelReset = async (fields: ArtifactSearchPanelFields) => {
        setLoading(true);

        const result = await Api.executeQuery<GetArtifactsQuery, GetArtifactsQueryResult>(
            ApiQuery.GetArtifacts, {
            tagSearchMode: ArtifactTagSearchMode.ExactMatch,
        });
        setRows(result.rows);

        setLoading(false);
    };

    useEffect(() => {
        const artifactId = + ((hash || "").replace("#", "").trim());
        if (artifactId > 0) {
            setSelectedArtifactId(artifactId)
            dialog.handleOpen(artifactId);
        } else {
            setSelectedArtifactId(undefined)
            dialog.handleClose();
        }

        // Listen for hash change
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hash])


    useMount(() => {

        if (!auth.authState.principal?.hasPermission(Permission.ArtifactsRead)) {
            navigate(paths.error403);
            return;
        }

        async function getLookupSelectFieldItems(apiQuery: ApiQuery): Promise<FormSelectItem<number>[]> {
            const result = await Api.executeQuery<GetLookupRowsQuery, GetLookupRowsQueryResult>(apiQuery, {});
            const rows = result?.rows.map<FormSelectItem<number>>(r => ({ label: r.name, value: r.id })) || [];
            rows.unshift({ value: -1, label: "All" })
            return rows;
        }

        const loadData = async () => {

            try {
                const artifactDescriptions = await getLookupSelectFieldItems(ApiQuery.GetArtifactDescriptions);
                const artifactTypes = await getLookupSelectFieldItems(ApiQuery.GetArtifactTypes);
                const operations = await getLookupSelectFieldItems(ApiQuery.GetOperations);
                const wreckSites = (await Api.executeQuery<GetWreckSitesQuery, GetWreckSitesQueryResult>(ApiQuery.GetWreckSites, {}))?.rows;
                const vessels = (await Api.executeQuery<GetVesselsQuery, GetVesselsQueryResult>(ApiQuery.GetVessels, {}))?.rows;

                const tagSearchModes = Object.keys(ArtifactTagSearchMode)
                    .filter((key) => isNaN(Number(key)))
                    .map((key) => ({
                        value: ArtifactTagSearchMode[key as keyof typeof ArtifactTagSearchMode],
                        label: splitCamelCase(key),
                    }));

                setArtifactSearchPanelDatasources(
                    {
                        artifactDescriptions,
                        artifactTypes,
                        operations,
                        wreckSites,
                        vessels,
                        tagSearchModes
                    }
                )
                const result = await Api.executeQuery<GetArtifactsQuery, GetArtifactsQueryResult>(
                    ApiQuery.GetArtifacts, {
                    tagSearchMode: ArtifactTagSearchMode.ExactMatch,
                });
                setRows(result.rows);

                setLoading(false);
            } catch (err) {
                setErrors(err.response.data.errors || [err.response.statusText])
                setLoading(false);
            }
        }

        loadData();
    });

    return (


        <Box
            component="main"
            sx={{
                display: 'flex',
                flex: '1 1 auto',
                overflow: 'hidden',
                position: 'relative',

            }}
        >
            <Box
                ref={rootRef}
                sx={{
                    display: 'flex',
                    position: 'absolute',
                    right: 0,
                    top: 0,
                    left: 0,
                    bottom: 0
                }}
            >

                <ArtifactSearchPanel
                    datasources={artifactSearchPanelDatasources}
                    container={rootRef.current}
                    open={searchPanelOpen}
                    onOpenChanged={setSearchPanelOpen}
                    onSubmit={handleSearchPanelSubmit}
                    onReset={handleSearchPanelReset}
                />

                <ArtifactGridContainer open={searchPanelOpen}>
                    <Stack spacing={4}>
                        <Stack
                            alignItems="flex-start"
                            direction="row"
                            justifyContent="space-between"
                            spacing={3}
                        >
                            <Stack
                                alignItems="center"
                                direction="row"
                                spacing={2}
                            >

                                <Typography variant="h4">
                                    Transfer Artifacts
                                </Typography>
                            </Stack>
                            <Stack
                                alignItems="center"
                                direction="row"
                                spacing={1}
                            >


                            </Stack>

                        </Stack>
                        <Stack>
                            <Accordion defaultExpanded>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1-content"
                                    id="panel1-header"
                                >
                                    <Typography variant="h5">Transfer Batch (0)</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Stack
                                        alignItems="flex-start"
                                        direction="row"
                                        justifyContent="space-between"
                                        spacing={3}
                                    >
                                        <Stack
                                            alignItems="center"
                                            direction="row"
                                            spacing={2}
                                        >
                                            &nbsp;
                                        </Stack>
                                        <Stack
                                            alignItems="center"
                                            direction="row"
                                            spacing={1}
                                        >

                                            <Button
                                                startIcon={(
                                                    <SvgIcon>
                                                        <MoveDownIcon />
                                                    </SvgIcon>
                                                )}
                                                variant="contained"
                                                size='small'
                                                disabled={true}
                                            >
                                                Transfer
                                            </Button>
                                            <Button
                                                color="inherit"
                                                component={RouterLink}
                                                disabled={false}
                                                href={paths.artifacts.transfer}
                                            >
                                                Cancel
                                            </Button>
                                        </Stack>

                                    </Stack>
                                    <Typography>
                                    <UIDataGrid
                                        loading={false}
                                        hideFooterPagination={false}
                                        rows={[]}
                                        columns={columns}
                            
                                        disableColumnSelector={true}
                                        onRowClick={(e) => handleArtifactSelect(e.row['id'])}
                                        slotProps={{
                                            toolbar: {
                                                showQuickFilter: false,
                                                csvOptions: { disableToolbarButton: true },
                                                printOptions: { disableToolbarButton: true },
                                            },
                                        }}
                                        RowPointer={true}
                        
                                    />
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                        </Stack>
                        <UIMessage
                            type="error"
                            messages={errors}
                            onClose={() => setErrors([])} />
                        <Stack>
                            <Accordion defaultExpanded>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1-content"
                                    id="panel1-header"
                                >
                                    <Typography variant="h5">
                                        Search Results
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Stack
                                        alignItems="flex-start"
                                        direction="row"
                                        justifyContent="space-between"
                                        spacing={3}
                                    >
                                        <Stack
                                            alignItems="center"
                                            direction="row"
                                            spacing={2}
                                        >
                                            &nbsp;
                                        </Stack>
                                        <Stack
                                            alignItems="center"
                                            direction="row"
                                            spacing={1}
                                        >

                                            <Button
                                                startIcon={(
                                                    <SvgIcon>
                                                        <PlusIcon />
                                                    </SvgIcon>
                                                )}
                                                variant="contained"
                                                disabled={!addButtonAccessible}
                                                size='small'
                                            >
                                                Add to Batch
                                            </Button>
                                        </Stack>

                                    </Stack>
                                    <UIDataGrid
                                        loading={loading}
                                        hideFooterPagination={loading}
                                        rows={rows}
                                        columns={columns}
                                        columnVisibilityModel={columnVisibilityModel}
                                        onColumnVisibilityModelChange={(newModel) =>
                                            setColumnVisibilityModel(newModel)
                                        }
                                        disableColumnSelector={false}
                                        onRowClick={(e) => handleArtifactSelect(e.row['id'])}
                                        slotProps={{
                                            toolbar: {
                                                showQuickFilter: true,
                                                csvOptions: { disableToolbarButton: true },
                                                printOptions: { disableToolbarButton: true },
                                            },
                                        }}
                                        RowPointer={true}
                                        checkboxSelection={true}
                                    />
                                </AccordionDetails>
                            </Accordion>
                        </Stack>

                    </Stack>

                </ArtifactGridContainer>

                <ArtifactDrawer
                    container={rootRef.current}
                    onClose={() => handleArtifactDrawerClose()}
                    open={dialog.open}
                    artifactId={selectedArtifactId}
                />
            </Box>

        </Box>

    );
};

export default ArtifactTransferPage;

