import * as Yup from 'yup';
import { useFormik } from 'formik';
import { Button, Stack, TextField, Typography } from '@mui/material';
import { Seo } from 'src/shared/components/seo';
import type { AuthContextType } from 'src/shared/contexts/auth-context';
import { useAuth } from 'src/shared/hooks/use-auth';
import { useIsMounted } from 'src/shared/hooks/use-is-mounted';
import { useState } from 'react';
import { Api } from 'src/api/api';
import { ApiCommand } from 'src/api/api-command';
import UIMessage from 'src/shared/components/ui/ui-message';
import { RequestPasswordResetCommand, RequestPasswordResetCommandResult } from 'src/api/commands/principal';
import { useMount } from 'src/shared/hooks/use-mount';

interface Values {
  email: string;
}

const initialValues: Values = {
  email: ''
};

const validationSchema = Yup.object({
  email: Yup
    .string()
    .max(255)
    .required('Email is required.')
});

enum PageMode {
  Form,
  Success
}

const ForgotPasswordPage = () => {
  const isMounted = useIsMounted();
  const { refreshAuthState } = useAuth<AuthContextType>();
  const [pageMode, setPageMode] = useState<PageMode>(PageMode.Form);
  const [errors, setErrors] = useState<string[]>([]);
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values, helpers): Promise<void> => {
      try {
        helpers.setSubmitting(true);
        setErrors([]);
        var command: RequestPasswordResetCommand = {
          userName: values.email
        };
        await Api.executeCommand<RequestPasswordResetCommand, RequestPasswordResetCommandResult>(
          ApiCommand.RequestPasswordReset,
          command);

        setPageMode(PageMode.Success);

      } catch (err) {
        if (isMounted()) {
          console.error(err);
          setErrors(err.response.data.errors || [err.response.statusText])
          helpers.setSubmitting(false);
        }
      }
    }
  });

  useMount(() => {
    refreshAuthState(); //Necessary for pages that must not be authenticted.
  });

  return (
    <>
      <Seo title="Register" />
      <div>

        <Stack
          sx={{ mb: 4 }}
          spacing={1}
        >
          <Typography
            variant="h5"
          >
            Forgot password?
          </Typography>
          <Typography
            variant="subtitle2"
            color="text.secondary">
            Enter your email address to reset your password.
          </Typography>
        </Stack>
        <form
          noValidate
          onSubmit={formik.handleSubmit}
        >

          <UIMessage
            type="error"
            messages={errors}
            onClose={() => setErrors([])} />


          {pageMode === PageMode.Form ? (
            <>
              <Stack spacing={3}>

                <TextField
                  error={!!(formik.touched.email && formik.errors.email)}
                  fullWidth
                  helperText={formik.touched.email && formik.errors.email}
                  label="Email Address"
                  name="email"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  type="text"
                  value={formik.values.email}
                />

              </Stack>
              <Button
                fullWidth
                sx={{ mt: 3 }}
                size="large"
                type="submit"
                variant="contained"
                disabled={formik.isSubmitting}
              >
                Send Password Reset Instructions
              </Button>
            </>
          ) :
            (
              <>
                <UIMessage
                  type="success"
                  messages={['Password reset instructions will be sent to the email address if it belongs to an active system user. You can close this page.']} />

              </>
            )}
        </form>
      </div>
    </>
  );
};


export default ForgotPasswordPage;
