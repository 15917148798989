import type { FC } from 'react';
import { useCallback, useEffect, useState } from 'react';
import { Box, Button, Drawer, IconButton, Link, Stack, SvgIcon, Typography } from '@mui/material';
import { ArtifactModel } from 'src/models/artifact';
import { ApiQuery } from 'src/api/api-query';
import { Api } from 'src/api/api';
import { GetArtifactQuery, GetArtifactQueryResult } from 'src/api/queries/artifact/get-artifact';
import { ArtifactView } from './artifact-view';
import Edit02Icon from '@untitled-ui/icons-react/build/esm/Edit02';
import XIcon from '@untitled-ui/icons-react/build/esm/X';
import { RouterLink } from 'src/shared/components/router-link';
import { UIProgress } from 'src/shared/components/ui/ui-progress';
import { useAuth } from 'src/shared/hooks/use-auth';
import { Permission } from 'src/models/permission';
import ArrowLeftIcon from '@untitled-ui/icons-react/build/esm/ArrowLeft';
import { GetArtifactFilesQuery, GetArtifactFilesQueryResult } from 'src/api/queries/artifact-file/get-artifact-files';
import { ArtifactFileCategory, ArtifactFileModel } from 'src/models/artifact-file';
import ArtifactTags from './artifact-tags';
import { GetArtifactTransfersQuery, GetArtifactTransfersQueryResult } from 'src/api/queries/artifact-transfer/get-artifact-transfers';
import { ArtifactTransferRow } from 'src/models/artifact-transfer';
import { GetArtifactCertificatesQuery, GetArtifactCertificatesQueryResult } from 'src/api/queries/artifact-certificate/get-artifact-certificates';
import { ArtifactCertificateRow } from 'src/models/artifact-certificate';
interface ArtifactDrawerProps {
  container?: HTMLDivElement | null;
  open?: boolean;
  onClose?: () => void;
  artifactId?: number;
}

export const ArtifactDrawer: FC<ArtifactDrawerProps> = (props) => {
  
  const { container, onClose, open, artifactId } = props;
  const auth = useAuth();
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [closeDrawerRequired, setCloseDrawerRequired] = useState<boolean>(false);
  const [artifact, setArtifact] = useState<ArtifactModel>();
  const [artifactTransfers, setArtifactTransfers] = useState<ArtifactTransferRow[]>([]);
  const [artifactCertificates, setArtifactCertificates] = useState<ArtifactCertificateRow[]>([]);
  const [artifactImageFiles, setArtifactImageFiles] = useState<ArtifactFileModel[]>([]);

  const editButtonAccessible = auth.authState.principal?.hasPermission(Permission.ArtifactsEdit) || false;
  const manageImagesButtonAccessible = auth.authState.principal?.hasAnyPermission([Permission.ArtifactsCreateImages, Permission.ArtifactsDeleteImages]) || false;
  const handleEditOpen = useCallback(
    () => {
      setIsEditing(true);
    },
    []
  );

  const onDrawerClose = () => {
    setArtifact(undefined);
    setIsEditing(false);
    onClose?.();
  }

  // const handleEditCancel = useCallback(
  //   () => {
  //     setIsEditing(false);
  //   },
  //   []
  // );

  useEffect(() => {
    let loadCanceled = false;

    async function onLoad() {

      if (!artifactId) {
        setArtifact(undefined);
        setArtifactImageFiles([]);
        setArtifactTransfers([]);
        setArtifactCertificates([]);
        return;
      }

      const getArtifactQueryResult = await Api.executeQuery<GetArtifactQuery, GetArtifactQueryResult>(
        ApiQuery.GetArtifact, { id: artifactId }
      );

      const getArtifactTransfersQueryResult = await Api.executeQuery<GetArtifactTransfersQuery, GetArtifactTransfersQueryResult>(
        ApiQuery.GetArtifactTransfers, { artifactId }
      );

      const getArtifactCertificatesQueryResult = await Api.executeQuery<GetArtifactCertificatesQuery, GetArtifactCertificatesQueryResult>(
        ApiQuery.GetArtifactCertificates, { artifactId }
      );

      const getArtifactImagesQueryResult = await Api.executeQuery<GetArtifactFilesQuery, GetArtifactFilesQueryResult>(
        ApiQuery.GetArtifactFiles, { artifactId , category: ArtifactFileCategory.Image }
      );

      if (loadCanceled || !getArtifactQueryResult.record) {
        setCloseDrawerRequired(true);
       return;
      }

      setArtifact(getArtifactQueryResult.record);
      setArtifactTransfers(getArtifactTransfersQueryResult.rows);
      setArtifactCertificates(getArtifactCertificatesQueryResult.rows);
      setArtifactImageFiles(getArtifactImagesQueryResult.rows);
    }

    onLoad();

    return () => {
      loadCanceled = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [artifactId]);


  useEffect(()=>{
    if(closeDrawerRequired === false) {
      return; // No action
    }

    setArtifact(undefined);
    setArtifactImageFiles([]);
    setArtifactTransfers([]);
    onDrawerClose();
    setCloseDrawerRequired(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [closeDrawerRequired])

  return (
    <Drawer
      anchor="right"

      open={open}
      PaperProps={{
        sx: {
          position: 'absolute',
          width: '100%',
          pointerEvents: 'auto',

        }
      }}
      SlideProps={{ container }}
      variant="persistent"
      hideBackdrop={true}
      ModalProps={{
        container,
        sx: {
          pointerEvents: 'none',
          position: 'absolute'
        }
      }}
      onClose={onClose}
    >

      <>
        <Stack
          alignItems="center"
          direction="row"
          justifyContent="space-between"
          sx={{
            px: 3,
            py: 2
          }}
        >
          <Stack spacing={2}>
            <div>
              <Link
                color="text.primary"
                onClick={onDrawerClose}
                sx={{
                  alignItems: 'center',
                  display: 'inline-flex',
                  cursor:'pointer'
                }}
                underline="hover"
              >
                <SvgIcon sx={{ mr: 1 }}>
                  <ArrowLeftIcon />
                </SvgIcon>
                <Typography variant="subtitle2">
                  Artifacts
                </Typography>
              </Link>
            </div>
            <Typography variant="h4">
            Artifact Details
          </Typography>
          </Stack>
   
          <IconButton
            color="inherit"
            onClick={onDrawerClose}
          >
            <SvgIcon>
              <XIcon fontSize={18} />
            </SvgIcon>
          </IconButton>



        </Stack>

        {!artifact ?
          (
            <UIProgress />
          ) : (
            <>
              <Stack
                sx={{
                  px: 3,
                  py: 2
                }}>

                <Stack
                  alignItems="flex-start"
                  direction={{
                    xs: 'column',
                    md: 'row'
                  }}
                  justifyContent="space-between"
                  spacing={4}
                >
                  {/* <Stack
                    alignItems="center"
                    direction="row"
                    spacing={2}
                  >

                    <Stack spacing={1}>
                      <Stack
                        alignItems="center"
                        direction="row"
                        spacing={1}
                      >
                        <Chip icon={<LocalOfferIcon />} label={artifact?.tag || "Untagged"} variant="outlined" size="medium" />
                        <Chip icon={<PlaceIcon />} label={artifact?.wreckSiteName} variant="outlined" size="medium" />
                        <Chip icon={<ArticleIcon />} label={artifact?.artifactDescriptionName} variant="outlined" size="medium" />
                        <Chip icon={<CalendarMonthIcon />} label={artifact?.year} variant="outlined" size="medium" />
                        {artifact?.highTicket && (
                          <Chip icon={<MonetizationOnIcon />} label={"High Ticket"} variant="outlined" size="medium" />
                        )}
                      </Stack>
                    </Stack>
                  </Stack> */}

                  <ArtifactTags artifact={artifact}/>
                  <Stack
                    alignItems="center"
                    direction="row"
                    spacing={2}
                  >
                    <Button
                      disabled={!editButtonAccessible}
                      color="inherit"
                      component={RouterLink}
                      startIcon={(
                        <SvgIcon>
                          <Edit02Icon />
                        </SvgIcon>
                      )}
                      href={`/artifacts/${artifact?.id}/edit`}
                    >
                      Edit
                    </Button>
                    <Button
                      disabled={!manageImagesButtonAccessible}
                      color="inherit"
                      component={RouterLink}
                      startIcon={(
                        <SvgIcon>
                          <Edit02Icon />
                        </SvgIcon>
                      )}
                      href={`/artifacts/${artifact?.id}/images`}
                    >
                      Manage Images
                    </Button>
                    {/* <Button
                  endIcon={(
                    <SvgIcon>
                      <ChevronDownIcon />
                    </SvgIcon>
                  )}
                  variant="contained"
                >
                  Actions
                </Button> */}
                  </Stack>
                </Stack>

              </Stack>

              <Box
                sx={{
                  px: 3,
                  py: 0
                }}
              >
                {
                  !isEditing
                    ? (
                      <ArtifactView
                        onEdit={handleEditOpen}
                        artifact={artifact}
                        artifactTransfers={artifactTransfers}
                        artifactImageFiles={artifactImageFiles}
                        artifactCertificates={artifactCertificates}
                      />
                    )
                    : (
                      <></>
                      // <ArtifactEdit
                      //   onCancel={handleEditCancel}
                      //   onSave={handleEditCancel}
                      //   Artifact={Artifact}
                      // />
                    )
                }
              </Box>
            </>
          )}
      </>

    </Drawer>
  );
};
