import type { FC, ReactNode } from 'react';
import { useCallback, useEffect, useState } from 'react';
import { useAuth } from 'src/shared/hooks/use-auth';
import { useRouter } from 'src/shared/hooks/use-router';
import { paths } from 'src/routes/paths';

interface AuthGuardProps {
	children: ReactNode;
}

export const AuthGuard: FC<AuthGuardProps> = (props) => {
	const { children } = props;
	const router = useRouter();
	const { authState } = useAuth();
	const [checked, setChecked] = useState(false);

	const check = useCallback(
		() => {
			if (!authState.isAuthenticated) {
				const searchParams = new URLSearchParams({ returnTo: window.location.href }).toString();
				const href = paths.login + `?&${searchParams}`;
				router.replace(href);
			} else {
				setChecked(true);
			}
		},
		[authState.isAuthenticated, router]
	);

	// Only check on mount, this allows us to redirect the user manually when auth state changes
	useEffect(
		() => {
			check();
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);

	if (!checked) {
		return null;
	}

	// If got here, it means that the redirect did not occur, and that tells us that the user is
	// authenticated / authorized.

	return <>{children}</>;
};

export const withAuthGuard = <P extends object>(Component: FC<P>): FC<P> => (props: P) => (
	<AuthGuard>
	  <Component {...props} />
	</AuthGuard>
  );
  