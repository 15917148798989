import { FC, ReactNode, createContext, useCallback, useContext, useState } from 'react';


const initialState = {
  sideNavWidth: 280,
  isOpen: true
}

interface NavContextType {
  handleToggle: () => void;
  isOpen: boolean;
  sideNavWidth: number;
}

const NavContext = createContext<NavContextType>({
  ...initialState,
  handleToggle: () => {}
});

interface NavProviderProps {
  children?: ReactNode;
}

export const NavProvider: FC<NavProviderProps> = (props) => {
  const { children } = props;
  const [isOpen, setIsOpen] = useState<boolean>(initialState.isOpen);

  const handleToggle = useCallback((): void => {
    setIsOpen(o=>!o);
  },[]);

  return (
    <NavContext.Provider
      value={{
        handleToggle,
        isOpen,
        sideNavWidth: initialState.sideNavWidth
      }}
    >
      {children}
    </NavContext.Provider>
  );
};

export const NavConsumer = NavContext.Consumer;

export const useNav = () => useContext(NavContext);
