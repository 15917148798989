import { FormControl, FormControlLabel, FormLabel, Unstable_Grid2 as Grid, Radio, RadioGroup } from "@mui/material";
import { UIFieldProps } from "./ui-field";
import React, { FC } from "react";

interface UIStatusRadioFieldProps extends UIFieldProps {
    name: string;
    label: string;
    required: boolean;
}

export enum UIStatusRadioFieldValue {
    Inactive = 0,
    Active = 1
}

const UIStatusRadioField: FC<UIStatusRadioFieldProps> = (props) => {
    const { name, label, required, formik } = props;

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>, value: string) => {
        formik?.setFieldValue(name, +value);
    }

    return !formik ? (<></>) : (
        <Grid
            xs={12}
            md={12}
        >
            <FormControl key={name + "control"} fullWidth>
                <FormLabel sx={{ pb: 2 }} id={`${name}label`}>{label}</FormLabel>
                <RadioGroup
                    row
                    aria-labelledby={`${name}label`}
                    name={name}
                    key={name}
                    value={formik.values[name]}
                    onBlur={formik.handleBlur}
                    onChange={handleChange}
                    aria-required={required}
                >
                    <FormControlLabel value={UIStatusRadioFieldValue.Active} control={<Radio />} label="Active" />
                    <FormControlLabel value={UIStatusRadioFieldValue.Inactive} control={<Radio />} label="Inactive"  />
                </RadioGroup>

            </FormControl>

        </Grid>
    );
};

export default UIStatusRadioField;
