import { FC, ReactNode, useState } from 'react';
import { createContext, useCallback, useEffect } from 'react';
import { Api } from 'src/api/api';
import { ApiQuery } from "src/api/api-query";
import { ApiCommand } from "src/api/api-command";
import { AuthenticateCommand, AuthenticateCommandResult } from '../../api/commands/principal';
import { GetPrincipalQuery, GetPrincipalQueryResult } from '../../api/queries/principal/get-principal';
import { Principal } from '../../models/principal';
import { MultiFactorAuthenticationMethod } from 'src/models/mfa';
import { paths } from 'src/routes/paths';
import { useNavigate } from 'react-router-dom';

const ACCESS_TOKEN_KEY = 'accessToken';
const REFRESH_TOKEN_KEY = 'refreshToken';

interface AuthState {
	isInitialized: boolean;
	isAuthenticated: boolean;
	principal: Principal | null;
}

const initialAuthState: AuthState = {
	isAuthenticated: false,
	isInitialized: false,
	principal: null
};

interface SignInResult {
	mfaRequired: boolean;
	mfa?: MultiFactorAuthenticationMethod;
}
export interface AuthContextType {
	authState: AuthState;
	signIn: (email: string, password: string, mfaCode?: string) => Promise<SignInResult>;
	signOut: () => Promise<void>;
	refreshAuthState: () => Promise<void>;
}

export const AuthContext = createContext<AuthContextType>({
	authState: initialAuthState,
	signIn: () => Promise.resolve({mfaRequired: false}),
	signOut: () => Promise.resolve(),
	refreshAuthState: () => Promise.resolve()
});

export const AuthProvider: FC<{ children: ReactNode; }> = (props) => {
	const { children } = props;
	const [authState, setAuthState] = useState<AuthState>(initialAuthState);
	const navigate = useNavigate();

	const initialize = useCallback(
		async (forceRetry: boolean = false): Promise<void> => {

			if(!forceRetry) {
				if(authState.isInitialized) 
				return;
			}
	
			try {

				const principalQuery = await Api.executeQuery<GetPrincipalQuery, GetPrincipalQueryResult>(
					ApiQuery.GetPrincipal,
					{});

				setAuthState({
					...authState,
					isInitialized: true,
					isAuthenticated: !!principalQuery.record,
					principal: !!principalQuery.record ? new Principal(principalQuery.record) : null
				});
		
			} catch (err) {

			
				setAuthState({
					...authState,
					isInitialized: true,
					isAuthenticated: false,
					principal: null
				});
				if(!err.response) {
					console.error(err);
					navigate(paths.error500);
				}else if(err.response.status === 403) {
					 navigate(paths.error403);
				} else {
					console.error(err);
				}
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[authState]
	);

	const signIn = useCallback(async (email: string, password: string, mfaCode?: string): Promise<SignInResult> => {

		let mfaRequired = false;
		const { accessToken, refreshToken, mfa } = await Api.executeCommand<AuthenticateCommand, AuthenticateCommandResult>(
			ApiCommand.Authenticate,
			{ userName: email, password, mfaCode: mfaCode || undefined });

		if(accessToken && refreshToken) {
			localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken);
			localStorage.setItem(ACCESS_TOKEN_KEY, accessToken);
			try {
				const principalQuery = await Api.executeQuery<GetPrincipalQuery, GetPrincipalQueryResult>(
					ApiQuery.GetPrincipal,
					{});
		
				setAuthState({
					...authState,
					isAuthenticated: !!principalQuery.record,
					principal: !!principalQuery.record ? new Principal(principalQuery.record) : null
				});
			}catch(err) {
				if(err.response.status === 403) {
					navigate(paths.error403);
			   } else {
				   console.error(err);
			   }
			}
			
		}else if(mfa) {
			mfaRequired = true;
		}

		return {mfaRequired, mfa}

	},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[authState]
	);

	const signOut = useCallback(
		async (): Promise<void> => {
			localStorage.removeItem(REFRESH_TOKEN_KEY);
			localStorage.removeItem(ACCESS_TOKEN_KEY);
			setAuthState({
				...authState,
				isAuthenticated: false,
				principal: null
			});
		},
		[authState]
	);

	const refreshAuthState = useCallback(
		async (): Promise<void> => { 
			initialize(true);
		}, [initialize]);

	useEffect(
		() => {
			initialize();
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);

	return (
		<AuthContext.Provider
			value={{
				authState,
				signIn,
				signOut,
				refreshAuthState
			}}
		>
			{children}
		</AuthContext.Provider>
	);
};

export const AuthConsumer = AuthContext.Consumer;
