
import { ApiQuery } from 'src/api/api-query';
import { Permission } from 'src/models/permission';
import LookupListPage from 'src/shared/pages/lookup-list-page';

const InvestorRelationsContactListPage = () => {
	return (
		<LookupListPage 
			permissions={[Permission.DataManagementRead]}
			title="Investor Relations Contacts"
			pageSize={25}
			apiQuery={ApiQuery.GetInvestorRelationsContacts}
			addButtonPath={undefined}
		/>
	)
}

export default InvestorRelationsContactListPage;
