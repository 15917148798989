import type { FC, ReactNode } from 'react';
import { useSettings } from 'src/shared/hooks/use-settings';
import { useSideNavSections } from './side-nav-config';
import { AuthGuard } from 'src/shared/guards/auth-guard';
import { Box } from '@mui/material';
import { SideNav } from './side-nav';
import { TopNav } from './top-nav';
import { NavConsumer, NavProvider } from '../../../hooks/use-nav';

// interface AppLayoutProps {
//   children?: ReactNode;
// }

// export const AppLayout: FC<AppLayoutProps> = withAuthGuard((props) => {
//   const settings = useSettings();
//   const sections = useSideNavSections();

//   return (
//     <VerticalLayout
//       sections={sections}
//       navColor={settings.navColor}
//       {...props}
//     />
//   );
// });


interface AppLayoutProps {
  children?: ReactNode;
}

export const AppLayout: FC<AppLayoutProps> = (props) => {
  const { children } = props;
  const settings = useSettings();
  const sections = useSideNavSections();

  return (
    <AuthGuard>
      <NavProvider>
        <NavConsumer>
          {(nav) => {
            return (
              <>
                <TopNav />
                {nav.isOpen && (
                  <SideNav
                    color={settings.navColor}
                    sections={sections}
                  />
                )}
                <Box sx= {{
                   display: 'flex',
                   flex: '1 1 auto',
                   maxWidth: '100%',
                   paddingLeft: (nav.isOpen ? nav.sideNavWidth : 0) + "px",
                }}>
                  <Box sx={{
                      display: 'flex',
                      flex: '1 1 auto',
                      flexDirection: 'column',
                      width: '100%'
                  }}>
                    {children}
                  </Box>
                </Box>
              </>
            );
          }}
        </NavConsumer>
      </NavProvider>
    </AuthGuard>
  );
};
